import { fetchMLAnnotationModel } from "helpers/apis/mlAnnotationModel";
import { MLAnnotationModel } from "models/mlAnnotationModel.model";
import {
  AIManagerScreenRouteModel,
  AIMangerViewRouteEnum,
} from "models/routes.model";
import AIModelManagerTable from "Pages/AIManager/AIModelManager/AIModelManagerTable";
import AIModelOverview from "Pages/AIManager/AIModelManager/AIModelOverview";
import CreateNewAIModelDialog from "Pages/AIManager/AIModelManager/CreateNewAIModelDialog";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export interface AIModelManagerOverviewDialogState {
  show: boolean;
  mlAnnotationModel: MLAnnotationModel | null;
}

function AIModelManager() {
  const params: AIManagerScreenRouteModel = useParams();

  const [overviewDialogState, setOverviewDialogState] =
    useState<AIModelManagerOverviewDialogState>({
      show: false,
      mlAnnotationModel: null,
    });

  useEffect(() => {
    if (params.view !== AIMangerViewRouteEnum.AIModels) return;
    if (!params.id || overviewDialogState.mlAnnotationModel) return;

    fetchMLAnnotationModel({ mlAnnotationModelID: params.id }).then(
      (fetchedTrainingSet) => {
        setOverviewDialogState({
          show: true,
          mlAnnotationModel: fetchedTrainingSet,
        });
      },
    );
  }, [params.id]);

  return (
    <>
      <AIModelManagerTable
        setOverviewMlAnnotationModel={setOverviewDialogState}
      />
      <CreateNewAIModelDialog />
      {overviewDialogState.show && (
        <AIModelOverview
          overviewDialogState={overviewDialogState}
          setOverviewDialogState={setOverviewDialogState}
          mlAnnotationModel={overviewDialogState.mlAnnotationModel}
        />
      )}
    </>
  );
}

export default AIModelManager;
