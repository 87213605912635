import { SendFilterModel } from "models/filter.model";

export interface TrainingSetModel {
  id: string;
  name: string;
  owner: string;
  user_group: string;
  created_at: string;
  updated_at: string;
  archived_at: string;
  training_attributes: TrainingSetAttribute[];
  possible_answers: string[];
  status: TrainingSetStatus;
}

export interface TrainingSetAttribute {
  attribute_id: string;
  dataset_id: string;
  query?: SendFilterModel[];
}

export interface TrainingSetModelCreate {
  name: string;
  training_attributes: TrainingSetAttribute[];
}

export enum TrainingSetStatus {
  SUBMITTED = "submitted",
  CREATED = "created",
  VALIDATING = "validating",
  VALIDATION_FAILED = "validation_failed",
}
