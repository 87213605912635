import { useAppDispatch, useAppSelector } from "store/hooks";
import DataTable, { DataTableColumn } from "components/Table/DataTable";

import { useRef, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { TrainingSetModel, TrainingSetStatus } from "models/trainingSet.model";
import EditName from "components/Inputs/EditName";
import TooltipTruncateEllipsis from "components/Tooltips/TooltipTruncateEllipsis";
import UserGroupSelection from "components/UtilComponents/UserGroupSelection";
import {
  deleteTrainingSet,
  fetchTrainingSet,
  patchTrainingSet,
} from "helpers/apis/trainingSet";
import { fetchTrainingSetsStore, setTrainingSets } from "store/aiManagerSlice";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import _ from "lodash";
import TrainingSetManagerTableActions from "Pages/AIManager/TrainingSetManager/TrainingSetManagerTableActions";
import CreateNewTrainingSetDialog from "Pages/AIManager/TrainingSetManager/CreateNewTrainingSetDialog";
import TrainingSetOverviewDialog from "Pages/AIManager/TrainingSetManager/TrainingSetOverviewDialog";
import { useKeycloak } from "@react-keycloak/web";
import SharedByTeamLabel from "components/Labels/SharedByTeamLabel";
import {
  AIManagerScreenRouteModel,
  AIMangerViewRouteEnum,
} from "models/routes.model";
import { getAIManagerPageRoute } from "routes/routesHelper";
import StatusLabel from "components/Labels/StatusLabel";

import { snakeCaseToText } from "components/utilFunctions";
import { filterByAllAppliedFilters } from "sections/ManagerFilters/managerFiltersHelpers";
import { useWebsocket } from "helpers/contexts/WebsocketContext";
import {
  dataUpdateMessageFunctionRouter,
  updateTrainingSetsWithNewTrainingSets,
} from "helpers/websockets/dataUpdateHelper";
import {
  WebsocketEventGroupEnum,
  WebSocketMessageModel,
} from "models/websockets.model";
import { handleDefaultOnMessageWebsocket } from "helpers/websockets";
import { getTrainingSetStatusColor } from "Pages/AIManager/TrainingSetManager/trainingSetStatusColor";

export interface TrainingSetManagerPopoverConfirmDialogState {
  show: boolean;
  action: "archive";
  trainingSetID: string | null;
}

export interface TrainingSetManagerOverviewDialogState {
  show: boolean;
  trainingSet: TrainingSetModel | null;
}

const TrainingSetManager = () => {
  const keycloak = useKeycloak();
  const history = useHistory();
  const params: AIManagerScreenRouteModel = useParams();
  const dispatch = useAppDispatch();

  const aiManagerSlice = useAppSelector((state) => state.aiManagerSlice);

  const { ws } = useWebsocket();

  const [confirmDialogState, setConfirmDialogState] =
    useState<TrainingSetManagerPopoverConfirmDialogState>({
      show: false,
      action: "archive",
      trainingSetID: null,
    });

  const inputRefArray = useRef<{ [key: string]: HTMLInputElement }>({});
  const [renameInputID, setRenameInputID] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  // Websocket update listener
  useEffect(() => {
    if (ws !== null) {
      ws.onmessage = (event) => {
        handleDefaultOnMessageWebsocket(event, dispatch, history);

        const webSocketMessage = JSON.parse(
          event.data,
        ) as WebSocketMessageModel;

        if (webSocketMessage.type === "data_update_message") {
          const eventGroup = dataUpdateMessageFunctionRouter(webSocketMessage);

          if (eventGroup === WebsocketEventGroupEnum.TRAINING_SET_UPDATE) {
            if (aiManagerSlice.trainingSets === null) return;

            const updatedTrainingSets = updateTrainingSetsWithNewTrainingSets(
              webSocketMessage,
              aiManagerSlice.trainingSets,
            );
            dispatch(setTrainingSets(updatedTrainingSets));
          }
        }
      };
    }
  }, [ws, aiManagerSlice.trainingSets]);

  const [overviewDialogState, setOverviewDialogState] =
    useState<TrainingSetManagerOverviewDialogState>({
      show: false,
      trainingSet: null,
    });

  useEffect(() => {
    if (params.view !== AIMangerViewRouteEnum.TrainingSets) return;
    if (!params.id || overviewDialogState.trainingSet) return;

    fetchTrainingSet({ training_set_id: params.id }, dispatch).then(
      (fetchedTrainingSet) => {
        setOverviewDialogState({ show: true, trainingSet: fetchedTrainingSet });
      },
    );
  }, [params.id]);

  const tableColumns: DataTableColumn[] = [
    {
      field: "name",
      headerName: "Name",
      span: 30,
      cell: (row) => {
        const trainingSet = row as TrainingSetModel;
        const isTrainingSetSharedByMyTeam =
          trainingSet?.owner !== keycloak?.keycloak?.idTokenParsed?.sub;
        return (
          <div
            className="flex overflow-hidden pr-3"
            onClick={(e) => e.stopPropagation()}
          >
            <EditName
              item={row as Record<string, any>}
              inputRefArray={inputRefArray}
              editInputID={renameInputID}
              setEditInputID={setRenameInputID}
              handleRename={(newName: string) =>
                handleRenameTrainingSet(trainingSet?.id, newName)
              }
              withToolTip={true}
              fontSize="text-normal"
            />
            {isTrainingSetSharedByMyTeam && <SharedByTeamLabel />}
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created",
      span: 15,
      cell: (row) => {
        const trainingSet = row as TrainingSetModel;
        const date = new Date(trainingSet?.created_at);
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <TooltipTruncateEllipsis className="pr-3" data-test="Created_value">
              {date.toLocaleString()}
            </TooltipTruncateEllipsis>
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      span: 15,
      cell: (row) => {
        const trainingSet = row as TrainingSetModel;
        return renderStatusChip(trainingSet?.status);
      },
    },
    {
      field: "user_group",
      headerName: "Team",
      span: 15,
      cell: (row) => {
        const trainingSet = row as TrainingSetModel;
        return (
          <div onClick={(e) => e.stopPropagation()} className="w-full">
            <UserGroupSelection
              selectedUserGroup={trainingSet?.user_group}
              onChanges={(newUserGroup: string) =>
                patchTrainingSet(
                  trainingSet?.id,
                  { user_group: newUserGroup },
                  dispatch,
                  setIsLoading,
                ).then(() => updateTrainingSets())
              }
            />
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "Actions",
      headerClassName: "justify-end",
      sortable: false,
      span: 25,
      cell: (row) => {
        const trainingSet = row as TrainingSetModel;
        return (
          <div
            onClick={(e) => e.stopPropagation()}
            className="flex flex-row-reverse justify-start"
          >
            <TrainingSetManagerTableActions
              trainingSet={trainingSet}
              setRenameInputID={setRenameInputID}
              setConfirmDialogState={setConfirmDialogState}
              setOverviewDialogState={setOverviewDialogState}
            />
          </div>
        );
      },
    },
  ];

  const renderStatusChip = (status: TrainingSetStatus) => {
    const color = getTrainingSetStatusColor(status);
    return <StatusLabel label={snakeCaseToText(status)} colorClass={color} />;
  };

  const updateTrainingSets = () => {
    dispatch(fetchTrainingSetsStore());
  };

  const handleRenameTrainingSet = (trainingSetID: string, newName: string) => {
    patchTrainingSet(
      trainingSetID,
      { name: newName },
      dispatch,
      setIsLoading,
    ).then(() => updateTrainingSets());
  };

  const handleDeleteAIModel = () => {
    if (!confirmDialogState.trainingSetID) return;

    deleteTrainingSet(
      {
        trainingSetID: confirmDialogState.trainingSetID,
      },
      dispatch,
      setIsLoading,
    ).then(() => {
      updateTrainingSets();
      setConfirmDialogState({ ...confirmDialogState, show: false });
    });
  };

  const renderConfirmationDialog = () => {
    if (!confirmDialogState.trainingSetID) return null;

    const trainingSetName =
      _.find(aiManagerSlice.trainingSets, [
        "id",
        confirmDialogState.trainingSetID,
      ])?.name || "";
    return (
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogState.show}
        setIsConfirmDialogOpen={(newState) =>
          setConfirmDialogState({ ...confirmDialogState, show: newState })
        }
        text={`Are you sure you want to delete the training set 
                "${trainingSetName}"?`}
        confirmButtonText="Confirm"
        handleOnSuccess={() => handleDeleteAIModel()}
      />
    );
  };

  const handleRowClick = (row: TrainingSetModel) => {
    setOverviewDialogState({ show: true, trainingSet: row });
    const url = getAIManagerPageRoute({
      view: AIMangerViewRouteEnum.TrainingSets,
      id: row.id,
    });
    history.push(url);
  };

  const filterTrainingSets = () => {
    if (!aiManagerSlice.trainingSets) return [];

    return filterByAllAppliedFilters(
      aiManagerSlice.trainingSets,
      aiManagerSlice.appliedFilters,
    ) as TrainingSetModel[];
  };

  return (
    <>
      <DataTable
        rows={filterTrainingSets() as []}
        columns={tableColumns}
        defaultSort={{ name: "created_at", direction: "desc" }}
        isLoading={aiManagerSlice.loadingTrainingSets}
        isDisabled={isLoading}
        searchValue={aiManagerSlice.searchValue}
        onRowClick={(row) => handleRowClick(row as TrainingSetModel)}
      />

      {/* Render confirmation dialog */}
      {renderConfirmationDialog()}

      {/* Render create new training set dialog */}
      {aiManagerSlice.isCreateNewTrainingSetModalOpen && (
        <CreateNewTrainingSetDialog />
      )}

      {/* Render training set overview dialog */}
      {overviewDialogState.show && (
        <TrainingSetOverviewDialog
          isOpen={overviewDialogState.show}
          setIsOpen={setOverviewDialogState}
          trainingSet={overviewDialogState.trainingSet}
        />
      )}
    </>
  );
};

export default TrainingSetManager;
