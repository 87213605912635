// A helper function to handle data update messages.

import { fetchActiveDataset } from "helpers/functions/datasets/datasetHelpers";
import { DatasetModel } from "models/dataset.model";
import { VisibilityStatus } from "models/global.model";
import { ExplorationScreenRouteModel } from "models/routes.model";
import {
  DataUpdateMessageModel,
  WebsocketEventEnum,
  WebsocketEventGroupEnum,
  WebSocketMessageModel,
} from "models/websockets.model";
import { matchPath } from "react-router-dom";
import { allUsersRoutes } from "routes";
import { resetStoreOnSubsetChange } from "store/util/resetStore";
import { AppDispatch } from "store/index";
import { History } from "history";
import { AnnotationRunModel } from "models/annotationRun.model";
import { AIAnnotationRunModel } from "models/aiAnnotationRun.model";
import { MLAnnotationModel } from "models/mlAnnotationModel.model";
import { TrainingSetModel } from "models/trainingSet.model";
import { ImportJobsModel } from "models/importJobs.model";
import { get } from "lodash";

// Should update the data based on the message.
export const handleDataUpdateMessage = (
  webSocketMessage: WebSocketMessageModel,

  dispatch: AppDispatch,
  history: History,
) => {
  const dataUpdateMessage = webSocketMessage as DataUpdateMessageModel;

  if (webSocketMessage.type !== "data_update_message") return;
  const dataMessageContent = dataUpdateMessage.content;

  switch (dataMessageContent?.event_type) {
    case WebsocketEventEnum.SUBSET_CREATION: {
      const subsetCreationDonePayload = dataMessageContent.data as DatasetModel;
      if (subsetCreationDonePayload.parent_dataset === null) return;
      handleSubsetCreationSuccess(
        subsetCreationDonePayload?.parent_dataset,
        subsetCreationDonePayload.id,
        dispatch,
        history,
      );
      break;
    }

    default:
      console.error("Unknown event type");
  }
};

export const dataUpdateMessageFunctionRouter = (
  message: DataUpdateMessageModel,
) => {
  const eventGroups: { [key: string]: WebsocketEventGroupEnum | null } = {
    // Annotation Run update events
    [WebsocketEventEnum.ANNOTATION_RUN_CREATION_DONE]:
      WebsocketEventGroupEnum.ANNOTATION_RUN_UPDATE,
    [WebsocketEventEnum.ANNOTATION_RUN_CREATION_FAILED]:
      WebsocketEventGroupEnum.ANNOTATION_RUN_UPDATE,
    [WebsocketEventEnum.ANNOTATION_RUN_ANNOTATION_DONE]:
      WebsocketEventGroupEnum.ANNOTATION_RUN_UPDATE,
    [WebsocketEventEnum.ANNOTATION_RUN_POST_PROCESSING_FAILED]:
      WebsocketEventGroupEnum.ANNOTATION_RUN_UPDATE,
    [WebsocketEventEnum.ANNOTATION_RUN_POST_PROCESSING]:
      WebsocketEventGroupEnum.ANNOTATION_RUN_UPDATE,
    [WebsocketEventEnum.ANNOTATION_RUN_POST_PROCESSING_DONE]:
      WebsocketEventGroupEnum.ANNOTATION_RUN_UPDATE,
    // AI Annotation Run update events
    [WebsocketEventEnum.AI_ANNOTATION_RUN_DONE]:
      WebsocketEventGroupEnum.AI_ANNOTATION_RUN_UPDATE,
    [WebsocketEventEnum.AI_ANNOTATION_RUN_FAILED]:
      WebsocketEventGroupEnum.AI_ANNOTATION_RUN_UPDATE,
    // AI Model update events
    [WebsocketEventEnum.ML_ANNOTATION_MODEL_TRAINING_DONE]:
      WebsocketEventGroupEnum.ML_ANNOTATION_MODEL_UPDATE,
    [WebsocketEventEnum.ML_ANNOTATION_MODEL_TRAINING_FAILED]:
      WebsocketEventGroupEnum.ML_ANNOTATION_MODEL_UPDATE,
    // Import Job update events
    [WebsocketEventEnum.DATASET_IMPORT_DONE]:
      WebsocketEventGroupEnum.DATASET_IMPORT_UPDATE,
    [WebsocketEventEnum.DATASET_IMPORT_FAILED]:
      WebsocketEventGroupEnum.DATASET_IMPORT_UPDATE,
    // Training set update events
    [WebsocketEventEnum.TRAINING_SET_CREATION_DONE]:
      WebsocketEventGroupEnum.TRAINING_SET_UPDATE,
    [WebsocketEventEnum.TRAINING_SET_CREATION_FAILED]:
      WebsocketEventGroupEnum.TRAINING_SET_UPDATE,
  };

  return get(eventGroups, message.content.event_type, null);
};

const handleSubsetCreationSuccess = (
  datasetID: string,
  subsetID: string,
  dispatch: AppDispatch,
  history: History,
) => {
  const route = history.location.pathname;
  const matchState = {
    exact: true,
    strict: false,
  };

  // Check if the current route is an exploration page
  const mediaMatch = matchPath(route, {
    path: allUsersRoutes.explorationMediaPage.path,
    ...matchState,
  });
  const objectMatch = matchPath(route, {
    path: allUsersRoutes.explorationObjectPage.path,
    ...matchState,
  });
  const instanceMatch = matchPath(route, {
    path: allUsersRoutes.explorationInstancePage.path,
    ...matchState,
  });

  let match = mediaMatch;
  if (match === null) {
    match = objectMatch;
  }
  if (match === null) {
    match = instanceMatch;
  }

  // If the current route is an exploration page, reset the store and fetch the new subset
  if (match !== null) {
    const matchParams: ExplorationScreenRouteModel =
      match.params as ExplorationScreenRouteModel;
    if (matchParams.id === datasetID && matchParams.subset_id === subsetID) {
      resetStoreOnSubsetChange(dispatch);
      fetchActiveDataset(dispatch, {
        datasetId: datasetID,
        subset_id: subsetID,
        visibility_statuses: [
          VisibilityStatus.Visible,
          VisibilityStatus.CreatingSubset,
        ],
      });
    }
  }
};

export const updateAnnotationRunStatus = (message: DataUpdateMessageModel) => {
  const annotationRun = message.content.data as AnnotationRunModel;
  return annotationRun;
};

export const updateAnnotationRunsWithNewAnnotationRun = (
  message: DataUpdateMessageModel,
  annotationRuns: AnnotationRunModel[],
) => {
  const newAnnotationRun = message.content.data as AnnotationRunModel;
  const newAnnotationRunIndex = annotationRuns.findIndex(
    (annotationRun) => annotationRun.id === newAnnotationRun.id,
  );

  let updatedAnnotationRuns = [...annotationRuns];
  if (newAnnotationRunIndex === -1) {
    return [newAnnotationRun, ...updatedAnnotationRuns];
  } else {
    updatedAnnotationRuns = [
      ...updatedAnnotationRuns.slice(0, newAnnotationRunIndex),
      newAnnotationRun,
      ...updatedAnnotationRuns.slice(newAnnotationRunIndex + 1),
    ];
    return updatedAnnotationRuns;
  }
};

export const updateAIAnnotationRunsWithNewAIAnnotationRun = (
  message: DataUpdateMessageModel,
  aiAnnotationRuns: AIAnnotationRunModel[],
) => {
  const newAIAnnotationRun = message.content.data as AIAnnotationRunModel;
  const newAIAnnotationRunIndex = aiAnnotationRuns.findIndex(
    (aiAnnotationRun) => aiAnnotationRun.id === newAIAnnotationRun.id,
  );

  let updatedAIAnnotationRuns = [...aiAnnotationRuns];
  if (newAIAnnotationRunIndex === -1) {
    return [newAIAnnotationRun, ...updatedAIAnnotationRuns];
  } else {
    updatedAIAnnotationRuns = [
      ...updatedAIAnnotationRuns.slice(0, newAIAnnotationRunIndex),
      newAIAnnotationRun,
      ...updatedAIAnnotationRuns.slice(newAIAnnotationRunIndex + 1),
    ];
    return updatedAIAnnotationRuns;
  }
};

export const updateAIModelsWithNewAIModels = (
  message: DataUpdateMessageModel,
  aiModels: MLAnnotationModel[],
) => {
  const newAIModel = message.content.data as MLAnnotationModel;
  const newAIModelIndex = aiModels.findIndex(
    (aiModel) => aiModel.id === newAIModel.id,
  );

  let updatedAIModels = [...aiModels];
  if (newAIModelIndex === -1) {
    return [newAIModel, ...updatedAIModels];
  } else {
    updatedAIModels = [
      ...updatedAIModels.slice(0, newAIModelIndex),
      newAIModel,
      ...updatedAIModels.slice(newAIModelIndex + 1),
    ];
    return updatedAIModels;
  }
};

export const updateTrainingSetsWithNewTrainingSets = (
  message: DataUpdateMessageModel,
  trainingSets: TrainingSetModel[],
) => {
  const newTrainingSet = message.content.data as TrainingSetModel;
  const newTrainingSetIndex = trainingSets.findIndex(
    (trainingSet) => trainingSet.id === newTrainingSet.id,
  );

  let updatedTrainingSets = [...trainingSets];
  if (newTrainingSetIndex === -1) {
    return [newTrainingSet, ...updatedTrainingSets];
  } else {
    updatedTrainingSets = [
      ...updatedTrainingSets.slice(0, newTrainingSetIndex),
      newTrainingSet,
      ...updatedTrainingSets.slice(newTrainingSetIndex + 1),
    ];
    return updatedTrainingSets;
  }
};

export const updateImportJobsWithNewNewImportJob = (
  message: DataUpdateMessageModel,
  importJobs: ImportJobsModel[],
) => {
  const newImportJob = message.content.data as ImportJobsModel;
  const newImportJobIndex = importJobs.findIndex(
    (importJob) => importJob.id === newImportJob.id,
  );

  let updatedImportJobs = [...importJobs];
  if (newImportJobIndex === -1) {
    return [newImportJob, ...updatedImportJobs];
  } else {
    updatedImportJobs = [
      ...updatedImportJobs.slice(0, newImportJobIndex),
      newImportJob,
      ...updatedImportJobs.slice(newImportJobIndex + 1),
    ];
    return updatedImportJobs;
  }
};
