import { SubsetsManagerScreenTabs } from "Pages/SubsetsManager";
import _ from "lodash";
import { DatasetModel, Mediatype } from "models/dataset.model";
import { useAppSelector } from "store/hooks";
import StandardTabs from "components/Tabs/StandardTabs";

type Props = {
  subsets: DatasetModel[];
  selectedTabValue: string;
  onTabClick: (tab: SubsetsManagerScreenTabs) => void;
};

const SubsetTabs = ({ subsets, selectedTabValue, onTabClick }: Props) => {
  const mediatype = useAppSelector(
    (state) => state.datasetSlice.activeDataSet?.mediatype,
  );

  const tabs = [
    {
      label: "All",
      value: "all",
      count: subsets?.length,
    },
    {
      label: mediatype === Mediatype.video ? "Video subsets" : "Media subsets",
      value: "media",
      count: _.filter(subsets, { subset_type: "media" })?.length,
    },
    {
      label: "Instance subsets",
      value: "instance",
      count: _.filter(subsets, { subset_type: "instance" })?.length,
    },
    {
      label: "Object subsets",
      value: "media_object",
      count: _.filter(subsets, { subset_type: "media_object" })?.length,
    },
  ];

  return (
    <div className="w-full pb-4 pt-4">
      <StandardTabs
        tabs={tabs}
        selectedTab={selectedTabValue}
        setSelectedTab={(tab) => onTabClick(tab as SubsetsManagerScreenTabs)}
      />
    </div>
  );
};

export default SubsetTabs;
