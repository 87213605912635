import { Dialog } from "@mui/material";
import TrainingSetOverview from "sections/TrainingSetOverview";
import useTrainingSetOverview from "helpers/hooks/useTrainingSetOverview";
import { TrainingSetManagerOverviewDialogState } from "Pages/AIManager/TrainingSetManager";
import { TrainingSetModel, TrainingSetStatus } from "models/trainingSet.model";
import StatusLabelFilled from "components/Labels/StatusLabelFilled";
import { snakeCaseToText } from "components/utilFunctions";

import { ReactComponent as CloseIcon } from "assets/close.svg";
import { useAppDispatch } from "store/hooks";
import {
  AIMangerViewEnum,
  setAIMangerView,
  setIsCreatingNewAIModelModalOpen,
  setNewAIModelTrainingSet,
} from "store/aiManagerSlice";
import { useHistory } from "react-router-dom";
import { getAIManagerPageRoute } from "routes/routesHelper";
import { AIMangerViewRouteEnum } from "models/routes.model";

type Props = {
  isOpen: boolean;
  setIsOpen: (newState: TrainingSetManagerOverviewDialogState) => void;
  trainingSet: TrainingSetModel | null;
};

const TrainingSetOverviewDialog = ({
  isOpen,
  setIsOpen,
  trainingSet,
}: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const {
    selectedTrainingSetAttributesMetaData,
    selectedTrainingSetDataset,
    selectedTrainingSetSubsets,
    isLoadingAttributeMetaData,
  } = useTrainingSetOverview(trainingSet);

  const handleCloseDialog = () => {
    setIsOpen({ show: false, trainingSet: null });
    const url = getAIManagerPageRoute({
      view: AIMangerViewRouteEnum.TrainingSets,
    });
    history.push(url);
  };

  const handleCreateAIModelFromTrainingSet = () => {
    dispatch(setAIMangerView(AIMangerViewEnum.AIModels));
    dispatch(setIsCreatingNewAIModelModalOpen(true));
    dispatch(setNewAIModelTrainingSet(trainingSet));
    handleCloseDialog();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleCloseDialog}>
      <div className="p-6 min-w-[500px]">
        {/* Header */}
        <div className="flex justify-between">
          <h3>Training set overview</h3>
          <div
            className="p-1 cursor-pointer"
            onClick={handleCloseDialog}
            data-test="close_button"
          >
            <CloseIcon className="w-3 h-3 text-paletteGray-9" />
          </div>
        </div>

        {trainingSet !== null && (
          <div className="pt-4">
            <TrainingSetOverview
              trainingSet={trainingSet}
              attributesMetaData={selectedTrainingSetAttributesMetaData}
              dataset={selectedTrainingSetDataset}
              subsets={selectedTrainingSetSubsets}
              isLoadingAttributeMetaData={isLoadingAttributeMetaData}
            />
          </div>
        )}

        {trainingSet && (
          <div className="w-full flex justify-end pt-6">
            <button
              className="button-layer"
              onClick={handleCreateAIModelFromTrainingSet}
              data-test="select_training_set_button_for_model"
              disabled={trainingSet.status !== TrainingSetStatus.CREATED}
            >
              Select this training set for a new AI model
            </button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default TrainingSetOverviewDialog;
