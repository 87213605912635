import {
  APIDeleteAxios,
  APIFetchAxios,
  APIPatchWithBodyAxios,
  APIPostWithBodyAxios,
} from "routes/Auth";
import { Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
  AnnotationRunCreate,
  AnnotationRunModel,
  AnnotationRunNodeModel,
} from "models/annotationRun.model";
import snackbarHelper from "helpers/snackbarHelperFn";

/**
 * An endpoint to create an annotation run
 * @param annotationRun The annotation run to be created
 * @param dispatch The dispatch function
 * @param setIsLoading The function to set the loading state
 * @returns The newly created annotation run
 */
export const postAnnotationRun = async (
  annotationRun: AnnotationRunCreate,
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
): Promise<AnnotationRunModel> => {
  const response = await APIPostWithBodyAxios(
    "/annotationRuns",
    annotationRun,
  ).catch((error) => {
    snackbarHelper(
      `Annotation run creation failed: ${error.response.data.detail}`,
      "error",
      6000,
    );
    return Promise.reject(error);
  });
  setIsLoading && setIsLoading(false);
  snackbarHelper("Annotation run created successfully!");
  return response?.data;
};

/**
 * An endpoint to fetch all annotation runs
 * @param {Dispatch} dispatch - The dispatch function
 * @returns {Promise<AnnotationRunModel[]>} - Returns an array of annotation runs
 */
export const fetchAnnotationRuns = async (
  dispatch: Dispatch,
): Promise<AnnotationRunModel[]> => {
  const response = await APIFetchAxios("/annotationRuns").catch((error) => {
    snackbarHelper("Annotation runs fetching failed!", "error");
    return Promise.reject(error);
  });
  return response?.data;
};

/**
 * An endpoint to fetch an annotation run
 * @param {string} params.annotationRunID - The annotation run ID
 * @param {Dispatch} dispatch - The dispatch function
 * @returns {Promise<AnnotationRunModel>} - Returns an annotation run
 */
export const fetchAnnotationRun = async (
  params: { annotationRunID: string },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
): Promise<AnnotationRunModel> => {
  setIsLoading && setIsLoading(true);
  const response = await APIFetchAxios(
    `/annotationRuns/${params?.annotationRunID}`,
  ).catch((error) => {
    const errorDetail = error?.response?.data?.detail;
    snackbarHelper(
      `Annotation run fetching: ${errorDetail}` ||
        "Annotation run fetching failed!",
      "error",
    );
    setIsLoading && setIsLoading(false);
    return Promise.reject(error);
  });
  setIsLoading && setIsLoading(false);
  return response?.data;
};

/**
 * An endpoint to fetch the nodes of an annotation run
 * @param params.annotationRunID The annotation run ID
 * @param dispatch
 * @returns The nodes of the annotation run
 */
export const fetchNodesOfAnnotationRun = async (
  params: { annotationRunID: string },
  dispatch: Dispatch,
): Promise<AnnotationRunNodeModel[]> => {
  const response = await APIFetchAxios(
    `/annotationRuns/${params?.annotationRunID}/annotationRunNodes`,
  ).catch((error) => {
    snackbarHelper(
      `Fetching annotation run ${params?.annotationRunID} nodes failed!!`,
      "error",
    );
    return Promise.reject(error);
  });
  return response?.data;
};

/**
 * An endpoint to delete an annotation run
 * @param {string} params.annotationRunID - The annotation run ID
 * @param {Dispatch} dispatch - The dispatch function
 * @param {Function} setIsLoading - The function to set the loading state
 * @returns {Promise<AxiosResponse>} - Returns a promise of the response
 */
export const deleteAnnotationRun = async (
  params: { annotationRunID: string },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  setIsLoading && setIsLoading(true);
  await APIDeleteAxios(`/annotationRuns/${params.annotationRunID}`)
    .then(() => {
      snackbarHelper("Annotation run archived successfully!");
      setIsLoading && setIsLoading(false);
      return Promise.resolve();
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        `Annotation run archive: ${errorDetail}` ||
          "Annotation run archive failed!",
        "error",
      );
      setIsLoading && setIsLoading(false);
      return Promise.reject();
    });
};

/**
 * An endpoint to duplicate an annotation run
 * @param {object} body - The body of the request
 * @param {string} body.annotationRunID - The annotation run ID
 * @param {Dispatch} dispatch - The dispatch function
 * @param {Function} setIsLoading - The function to set the loading state
 * @returns {Promise<AxiosResponse>} - Returns a promise
 */
export const postDuplicateAnnotationRun = async (
  body: { name: string },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  setIsLoading && setIsLoading(true);
  await APIPostWithBodyAxios("/annotationRuns", {
    ...body,
    name: `${body.name}_clone`,
  })
    .then(() => {
      snackbarHelper("Annotation run duplicated successfully!");
      setIsLoading && setIsLoading(false);
      return Promise.resolve();
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        `Annotation run duplication: ${errorDetail}` ||
          "Annotation run duplicated failed!",
        "error",
      );
      setIsLoading && setIsLoading(false);
      return Promise.reject();
    });
};

export const patchAnnotationRun = async (
  annotationRunID: string,
  body: {
    name?: string;
    user_group?: string;
  },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
): Promise<AnnotationRunModel> => {
  setIsLoading && setIsLoading(true);
  const response = await APIPatchWithBodyAxios(
    `/annotationRuns/${annotationRunID}`,
    body,
  ).catch((error: AxiosError<any>) => {
    const errorDetail = error?.response?.data?.detail;
    snackbarHelper(
      `Annotation run update: ${errorDetail}` ||
        "Annotation run update failed!",
      "error",
    );
    setIsLoading && setIsLoading(false);
    return Promise.reject();
  });
  snackbarHelper("Annotation run updated successfully!");
  setIsLoading && setIsLoading(false);
  return response?.data;
};

/**
 * An endpoint to fetch all annotation run nodes or a specific set of annotation run nodes by their IDs
 * @returns {Promise<AnnotationRunNodeModel[]>} - Returns an array of annotation runs
 */
export const fetchAnnotationRunNodes = async (
  annotation_run_node_ids?: string[],
): Promise<AnnotationRunNodeModel[]> => {
  const response = await APIFetchAxios("/annotationRunNodes", {
    annotation_run_node_ids: annotation_run_node_ids,
  }).catch((error) => {
    snackbarHelper("Annotation runs fetching failed!", "error");
    return Promise.reject(error);
  });
  return response?.data;
};

export const postAnnotationRunLivePreview = async (
  body: {
    dataset_id: string;
    subset_id: string;
    sample_size: number;
    gui_type: string;
    visualisation_type: string;
    color_map: Record<string, string>;
    gui_settings: Record<string, unknown>;
  },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
): Promise<Record<string, unknown>> => {
  setIsLoading && setIsLoading(true);
  const response = await APIPostWithBodyAxios(
    "/annotationRuns:sample_tasks",
    body,
  ).catch((error: AxiosError<any>) => {
    const errorDetail = error?.response?.data?.detail;
    const errorMessage = errorDetail
      ? `Annotation run live preview failed: ${errorDetail}`
      : "Annotation run live preview failed!";
    snackbarHelper(errorMessage, "error");
    setIsLoading && setIsLoading(false);
    return Promise.reject();
  });
  setIsLoading && setIsLoading(false);
  return response?.data;
};
