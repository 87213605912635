import StandardTabs, { StandardTabModel } from "components/Tabs/StandardTabs";
import { AIMangerViewEnum, setAIMangerView } from "store/aiManagerSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  getAIManagerPageRoute,
  getAIManagerViewParamFromEnum,
} from "routes/routesHelper";

import { FeatureFlagEnum } from "models/featureFlags.model";
import { useFeature } from "helpers/hooks/useFeature";
import { useItemFeatureGate } from "helpers/hooks/useItemFeatureGate";

const AIManagerTabs = () => {
  const history = useHistory();

  const dispatch = useAppDispatch();
  const aiManagerSlice = useAppSelector((state) => state.aiManagerSlice);
  const addFeatureItem = useItemFeatureGate();

  const tabs: (StandardTabModel & { featureFlag?: string })[] = [
    ...addFeatureItem(FeatureFlagEnum.AI_DATA_CURATION, {
      label: AIMangerViewEnum.TrainingSets,
      value: AIMangerViewEnum.TrainingSets,
      count: aiManagerSlice.trainingSets?.length,
    }),
    {
      label: AIMangerViewEnum.AIModels,
      value: AIMangerViewEnum.AIModels,
      count: aiManagerSlice.aiModels?.length,
    },

    {
      label: AIMangerViewEnum.AIAnnotationRuns,
      value: AIMangerViewEnum.AIAnnotationRuns,
      count: aiManagerSlice.aiAnnotationRuns?.length,
    },
  ];

  // TODO: remove once external users can see TrainingSets
  const canSeeTrainingSets = useFeature(FeatureFlagEnum.AI_DATA_CURATION);

  // TODO: remove once external users can see TrainingSets
  useEffect(() => {
    if (
      aiManagerSlice.view === AIMangerViewEnum.TrainingSets &&
      !canSeeTrainingSets
    ) {
      dispatch(setAIMangerView(AIMangerViewEnum.AIModels));
    }
  }, [aiManagerSlice.view]);

  return (
    <div className="w-full">
      <StandardTabs
        tabs={tabs}
        selectedTab={aiManagerSlice.view || ""}
        setSelectedTab={(clickedTab) => {
          dispatch(setAIMangerView(clickedTab as AIMangerViewEnum));
          const url = getAIManagerPageRoute({
            view: getAIManagerViewParamFromEnum(clickedTab as AIMangerViewEnum),
          });
          history.push(url);
        }}
      />
    </div>
  );
};

export default AIManagerTabs;
