import { useState, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import { DatasetModel, Mediatype } from "models/dataset.model";
import { resetStoreOnSubsetChange } from "store/util/resetStore";
import { fetchDataset, fetchSubsets } from "store/datasetSlice";

import selectMediatype from "helpers/functions/selectMediatype";
import DataTable, { DataTableColumn } from "components/Table/DataTable";
import EditName from "components/Inputs/EditName";
import MoreButton from "components/Buttons/MoreButton";
import StandardPopoverWrapper from "components/Popovers/StandardPopoverWrapper";
import {
  deleteSubset,
  postRenameSubset,
  postSplitSubset,
} from "helpers/apis/subsets";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import { SubsetTypeModel, VisibilityStatus } from "models/global.model";
import { SubsetsManagerScreenTabs } from "Pages/SubsetsManager";
import {
  getDownloadFile,
  postCreateReport,
  postCreateSubsetExportFile,
} from "helpers/apis/download";
import { SubsetsManagerScreenRouteModel } from "models/routes.model";
import { useKeycloak } from "@react-keycloak/web";
import { checkIfUserHavePermission } from "helpers/keycloakHelpers";
import { ReactComponent as DownloadIcon } from "assets/download.svg";
import { getExplorationRouteFromSelectedView } from "routes/routesHelper";
import { setSelectedView } from "store/appSlice";
import { getSelectedViewFromSubsetType } from "helpers/functions/selectedViewHelpers";
import SplitDataDialog from "components/Dialogs/SplitDataDialog";
import snackbarHelper from "helpers/snackbarHelperFn";
import { FeatureFlagEnum } from "models/featureFlags.model";

import { useItemFeatureGate } from "helpers/hooks/useItemFeatureGate";

interface Props {
  selectedTab?: string;
  handleCloseSubsetsDialog?: () => void;
  searchValue?: string;
}

const SubsetsTable = ({
  selectedTab,
  handleCloseSubsetsDialog,
  searchValue,
}: Props) => {
  const params: SubsetsManagerScreenRouteModel = useParams();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const addFeatureItem = useItemFeatureGate();

  const dataSetData = useAppSelector((state) => state.datasetSlice);
  const selectedView = useAppSelector((state) => state.appSlice.selectedView);

  const inputRefArray = useRef<{ [key: string]: HTMLInputElement }>({});
  const [editInputID, setEditInputID] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [clickedItemID, setClickedItemID] = useState<string | null>(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isSplitDataDialogOpen, setIsSplitDataDialogOpen] = useState(false);

  // focus on the input field when the editInputID changes
  useEffect(() => {
    if (editInputID) {
      inputRefArray.current?.[editInputID]?.focus();
    }
  }, [editInputID]);

  const handleSubsetClick = (subset: DatasetModel): void => {
    if (_.isNull(editInputID) && subset.parent_dataset) {
      const explorationRoute = getExplorationRouteFromSelectedView(
        selectedView,
        {
          id: subset?.parent_dataset,
          subset_id: subset?.id,
        },
      );
      if (history.location.pathname !== explorationRoute) {
        resetStoreOnSubsetChange(dispatch);
        const subsetType = getSelectedViewFromSubsetType(subset.subset_type);
        subsetType && dispatch(setSelectedView(subsetType));
        history.push(explorationRoute);
      }
      handleCloseSubsetsDialog && handleCloseSubsetsDialog();
    }
  };

  const columns: DataTableColumn[] = [
    {
      field: "name",
      headerName: "Name",
      span: 30,
      className: "font-bold",
      cell: (row) => {
        const subset = row as DatasetModel;
        return (
          <div className="flex gap-x-1 text-ellipsis overflow-hidden pr-1">
            <EditName
              item={row as Record<string, any>}
              inputRefArray={inputRefArray}
              editInputID={editInputID}
              setEditInputID={setEditInputID}
              handleRename={(newName: string) => {
                handleRenameSubset(newName, subset?.id);
              }}
              withToolTip
            />
          </div>
        );
      },
    },

    {
      field: "num_medias",
      headerName: `${selectMediatype(dataSetData?.activeDataSet?.mediatype)}s`,
      span: 15,
    },
    {
      field: "subset_type",
      headerName: "Subset type",
      span: 15,
      cell: (row) => {
        const subset = row as DatasetModel;
        return (
          <div className="truncate" data-test="Subset type_value">
            {/* Only view this when the dataset is an media.
            This could change when we add more adaptation for the video subsets */}
            {dataSetData?.activeDataSet?.mediatype === Mediatype.image &&
              (subset?.subset_type === "media_object"
                ? "Object"
                : _.upperFirst(subset?.subset_type))}
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "Status",
      span: 10,
      cell: (row) => {
        const subset = row as DatasetModel;
        return (
          <div data-test="Status_value">
            {subset.visibility_status === VisibilityStatus.CreatingSubset
              ? "In progress"
              : subset.visibility_status === VisibilityStatus.Visible
                ? "Available"
                : "null"}
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "",
      sortable: false,
      span: 12,
      cell: (row) => {
        const subset = row as DatasetModel;
        return subset?.export_id ? (
          <button
            className="button-layer flex gap-x-2"
            onClick={(e) => {
              e.stopPropagation();
              getDownloadFile(
                {
                  datasetID: params?.dataset_id,
                  fileID: subset?.export_id,
                },
                dispatch,
                setIsLoading,
              );
            }}
            data-test="download_json_button"
          >
            <DownloadIcon width={16} height={16} />
            Download JSON
          </button>
        ) : (
          <></>
        );
      },
    },
    {
      field: "",
      headerName: "",
      sortable: false,
      span: 15,
      cell: (row) => {
        const subset = row as DatasetModel;
        return (
          <div onClick={(e) => e.stopPropagation()}>
            {renderSplitDataButton(subset)}
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "",
      sortable: false,
      span: 3,
      cell: (row) => {
        const subset = row as DatasetModel;
        const itemsList = [
          ...addFeatureItem(FeatureFlagEnum.REPORTS, {
            label: "Create insights report",
            onItemClick: () => {
              params?.dataset_id &&
                postCreateReport(params?.dataset_id, subset?.id);
            },
          }),
          {
            label: "Create JSON export",
            onItemClick: () => {
              postCreateSubsetExportFile(
                {
                  datasetID: params?.dataset_id,
                  subsetID: subset?.id,
                },
                dispatch,
              );
            },
          },
          {
            label: "Rename",
            onItemClick: () => {
              setEditInputID(subset?.id);
            },
          },
          {
            label: "Delete",
            onItemClick: () => {
              setIsConfirmDialogOpen(true);
              setClickedItemID(subset?.id);
            },
            className: "text-paletteRed",
            disabled: subset?.object_category,
          },
        ];
        return (
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-full h-full flex justify-end items-center"
          >
            <StandardPopoverWrapper
              id={subset?.id}
              buttonElement={<MoreButton />}
              itemsList={itemsList}
            />
          </div>
        );
      },
    },
  ];

  const renderSplitDataButton = (row: DatasetModel) => {
    const isUserCustomContract = checkIfUserHavePermission(
      keycloak,
      "accessSplitSubsetFeature",
    );
    if (!isUserCustomContract) {
      return null;
    }
    if (
      row?.subset_type !== SubsetTypeModel.MediaObject ||
      row?.num_media_objects < 5
    )
      return null;

    return (
      <div
        className="button-layer"
        data-test="split_data_button"
        onClick={() => {
          setClickedItemID(row?.id);
          setIsSplitDataDialogOpen(true);
        }}
      >
        AI data preparation
      </div>
    );
  };

  const handleRenameSubset = (newName: string, subsetID: string) => {
    if (newName.length < 3) {
      snackbarHelper(
        `Subset name must be at least 3 characters long, but '${newName}' has ${newName.length} character(s).`,
        "error",
      );
      return;
    }

    postRenameSubset({ subsetID: subsetID, newName: newName }, dispatch).then(
      () => fetchNewSubsetsAndCurrentDataset(),
    );
  };

  const handleDelete = () => {
    if (!clickedItemID) return;
    deleteSubset({ subsetID: clickedItemID }, dispatch, setIsLoading)
      .then(() => {
        setIsConfirmDialogOpen(false);
        fetchNewSubsetsAndCurrentDataset();
      })
      .catch(() => {
        setIsConfirmDialogOpen(false);
      });
  };

  const fetchNewSubsetsAndCurrentDataset = () => {
    dispatch(
      fetchSubsets({
        query: { parentDataSetID: dataSetData?.activeParentDataset?.id || "" },
        visibility_statuses: [
          VisibilityStatus.Visible,
          VisibilityStatus.CreatingSubset,
        ],
      }),
    );
    dispatch(
      fetchDataset({
        query: { dataSetID: dataSetData?.activeDataSet?.id || "" },
      }),
    );
  };

  const filterSubsets = () => {
    let filteredSubsets = dataSetData?.subSets;
    if (selectedTab && selectedTab !== SubsetsManagerScreenTabs.all) {
      filteredSubsets = _.filter(dataSetData?.subSets, {
        subset_type: selectedTab as SubsetTypeModel,
      });
    }
    return filteredSubsets;
  };

  return (
    <>
      <DataTable
        rows={filterSubsets() as []}
        columns={columns}
        onRowClick={(row) => handleSubsetClick(row as DatasetModel)}
        defaultSort={{ name: "name", direction: "asc" }}
        searchValue={searchValue}
      />

      <ConfirmDialog
        isConfirmDialogOpen={isConfirmDialogOpen}
        setIsConfirmDialogOpen={setIsConfirmDialogOpen}
        text={`Are you sure you want to delete the subset "${_.find(
          dataSetData.subSets,
          ["id", clickedItemID],
        )?.name}"?`}
        handleOnSuccess={handleDelete}
        isLoading={isLoading}
      />

      <SplitDataDialog
        isDialogOpen={isSplitDataDialogOpen}
        setIsDialogOpen={setIsSplitDataDialogOpen}
        subset={_.find(dataSetData.subSets, ["id", clickedItemID])}
        onSplitSubset={(aiSubsetName, referenceSubsetName, subsetSize) => {
          if (!clickedItemID) return;
          postSplitSubset(
            {
              datasetID: params.dataset_id,
              subsetID: clickedItemID,
              aiSubsetName,
              referenceSubsetName,
              subsetSize,
            },
            dispatch,
            setIsLoading,
          ).then(() => setIsSplitDataDialogOpen(false));
        }}
        isLoading={isLoading}
      />
    </>
  );
};

export default SubsetsTable;
