import { ReactComponent as DeleteIcon } from "assets/delete.svg";
import { ReactComponent as EditIcon } from "assets/edit.svg";

import {
  AIAnnotationRunModel,
  AIAnnotationRunStatusEnum,
} from "models/aiAnnotationRun.model";
import { AIAnnotationRunManagerPopoverConfirmDialogState } from "Pages/AIManager/AIAnnotationRunManager/AIAnnotationRunManagerTable";
import ExploreAIAnnotationRunResultsButton from "Pages/AIManager/AIAnnotationRunManager/ExploreAIAnnotationRunResultsButton";

type Props = {
  aiAnnotationRun: AIAnnotationRunModel;
  setRenameInputID: (id: string | null) => void;
  setConfirmDialogState: (
    newState: AIAnnotationRunManagerPopoverConfirmDialogState,
  ) => void;
};

const AIAnnotationRunManagerTableActions = ({
  aiAnnotationRun,
  setRenameInputID,
  setConfirmDialogState,
}: Props) => {
  const renderExploreResultsButton = () => {
    if (aiAnnotationRun.status !== AIAnnotationRunStatusEnum.DONE) {
      return null;
    }

    return (
      <ExploreAIAnnotationRunResultsButton aiAnnotationRun={aiAnnotationRun} />
    );
  };

  return (
    <div className="flex flex-row-reverse gap-x-1 items-center">
      <button
        className="button-select-layer p-1"
        onClick={() => setRenameInputID(aiAnnotationRun?.id)}
        data-test="rename_button"
      >
        <EditIcon className="text-paletteGray-9 w-[18px]" />
      </button>

      <button
        className="p-1 button-select-layer"
        onClick={() =>
          setConfirmDialogState({
            show: true,
            action: "archive",
            aiAnnotationRunID: aiAnnotationRun?.id,
          })
        }
        data-test="delete_button"
      >
        <DeleteIcon className="text-paletteGray-9 w-[18px]" />
      </button>
      <div className="w-fit">{renderExploreResultsButton()}</div>
    </div>
  );
};

export default AIAnnotationRunManagerTableActions;
