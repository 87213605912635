import { Dialog } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import { useEffect, useState } from "react";
import { DatasetModel } from "models/dataset.model";
import { getDataset } from "helpers/apis/datasets";

import { useHistory } from "react-router-dom";
import { getAIManagerPageRoute } from "routes/routesHelper";
import {
  AIAnnotationRunModel,
  AIAnnotationRunStatusEnum,
} from "models/aiAnnotationRun.model";
import { fetchAIAnnotationRun } from "helpers/apis/aiAnnotationRun";
import ExploreAIAnnotationRunResultsButton from "Pages/AIManager/AIAnnotationRunManager/ExploreAIAnnotationRunResultsButton";
import AIAnnotationRunStatusChip from "Pages/AIManager/AIAnnotationRunManager/AIAnnotationRunStatusChip";
import Loading from "components/UtilComponents/Loading";
import { AIMangerViewRouteEnum } from "models/routes.model";
import { MLAnnotationModel } from "models/mlAnnotationModel.model";
import { fetchMLAnnotationModel } from "helpers/apis/mlAnnotationModel";

export interface AIAnnotationRunManagerOverviewDialogState {
  show: boolean;
  aiAnnotationRun: AIAnnotationRunModel | null;
}

type Props = {
  overviewDialog: AIAnnotationRunManagerOverviewDialogState;
  setOverviewDialog: (
    newState: AIAnnotationRunManagerOverviewDialogState,
  ) => void;
};

const AIAnnotationRunOverview = ({
  overviewDialog,
  setOverviewDialog,
}: Props) => {
  const history = useHistory();

  const [
    selectedAIAnnotationRunWithNodes,
    setSelectedAIAnnotationRunWithNodes,
  ] = useState<AIAnnotationRunModel | null>(null);

  const [dataset, setDataset] = useState<DatasetModel | null>(null);
  const [subset, setSubset] = useState<DatasetModel | null>(null);
  const [mlAnnotationModel, setMLAnnotationModel] =
    useState<MLAnnotationModel | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (overviewDialog.aiAnnotationRun !== null) {
      setIsLoading(true);

      fetchAIAnnotationRun({
        aiAnnotationRunID: overviewDialog.aiAnnotationRun.id,
      }).then((aiAnnotationRun) => {
        setSelectedAIAnnotationRunWithNodes(aiAnnotationRun);
        Promise.all([
          getDataset({
            datasetID: aiAnnotationRun.dataset_id,
          }).then((data) => {
            setDataset(data);
          }),
          getDataset({
            datasetID: aiAnnotationRun.subset_id,
          }).then((data) => {
            setSubset(data);
          }),
          fetchMLAnnotationModel({
            mlAnnotationModelID: aiAnnotationRun.ml_annotation_model_id,
          }).then((data) => {
            setMLAnnotationModel(data);
          }),
        ]).finally(() => setIsLoading(false));
      });
    }
  }, [overviewDialog.aiAnnotationRun]);

  const renderIsLoading = () => {
    return (
      <div className="w-full max-h-[850px] p-8 flex">
        <Loading />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="w-full max-h-[850px] flex flex-col">
        {/* Header */}
        <header className="p-4 flex justify-between">
          <div className="text-xl text-paletteBlack-2">
            AI annotation run overview
          </div>
          <button className="p-1 cursor-pointer" onClick={handleCloseDialog}>
            <CloseIcon className="w-3 h-3 text-paletteGray-9" />
          </button>
        </header>

        {/* Content */}
        <article className="px-2 min-h-0 flex-1 overflow-y-auto">
          <div className="w-full p-6 flex flex-col gap-y-3">
            {renderAnnotationRunDetails()}
            {dataset !== null && renderRow("Dataset", dataset?.name)}
            {renderDataRow()}
          </div>
        </article>
      </div>
    );
  };

  const renderAnnotationRunDetails = () => {
    if (selectedAIAnnotationRunWithNodes === null) {
      return null;
    }
    const date = new Date(selectedAIAnnotationRunWithNodes.created_at);

    return (
      <>
        {/* Name and status */}
        <div className="flex justify-between items-center">
          <div className="flex gap-x-4">
            <div className="label-layer w-fit">
              {selectedAIAnnotationRunWithNodes?.name}
            </div>
            <AIAnnotationRunStatusChip
              status={selectedAIAnnotationRunWithNodes?.status}
            />
          </div>
          <div className="flex gap-x-2">{renderExploreResultsButton()}</div>
        </div>

        {/* Annotation run fields */}
        {renderRow("Created at", date.toLocaleString())}
        {renderRow("Team", selectedAIAnnotationRunWithNodes.user_group || "-")}
        {renderRow("Model", mlAnnotationModel?.name || "-")}
      </>
    );
  };

  const renderExploreResultsButton = () => {
    if (selectedAIAnnotationRunWithNodes === null) return null;

    if (
      selectedAIAnnotationRunWithNodes.status !== AIAnnotationRunStatusEnum.DONE
    )
      return null;

    return (
      <ExploreAIAnnotationRunResultsButton
        aiAnnotationRun={selectedAIAnnotationRunWithNodes}
      />
    );
  };

  const renderRow = (key: string, value: string) => {
    return (
      <div className="w-full flex">
        <div className="w-1/2 text-paletteGray-10">{key}</div>
        <div className="w-1/2 text-paletteBlack-2">{value}</div>
      </div>
    );
  };

  const renderDataRow = () => {
    if (dataset === null || subset === null) {
      return null;
    }

    return (
      <div className="w-full flex">
        <div className="w-1/2 text-paletteGray-10">Data</div>
        <div className="w-1/2  gap-x-2">
          <div className="flex">{subset?.name}</div>
          <div className="flex items-end text-sm text-paletteGray-11">
            {subset?.num_medias} medias - {subset?.num_media_objects} objects
          </div>
        </div>
      </div>
    );
  };

  const handleCloseDialog = () => {
    setOverviewDialog({ show: false, aiAnnotationRun: null });
    const url = getAIManagerPageRoute({
      view: AIMangerViewRouteEnum.AIAnnotationRuns,
    });
    history.push(url);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={overviewDialog.show}
      onClose={handleCloseDialog}
    >
      {isLoading ? renderIsLoading() : renderBody()}
    </Dialog>
  );
};

export default AIAnnotationRunOverview;
