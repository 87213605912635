import { Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import snackbarHelper from "helpers/snackbarHelperFn";
import {
  APIDeleteAxios,
  APIFetchAxios,
  APIPatchWithBodyAxios,
  APIPostWithBodyAxios,
} from "routes/Auth";
import {
  MLAnnotationModel,
  MLAnnotationModelCreate,
} from "models/mlAnnotationModel.model";

export const fetchMLAnnotationModels = async (
  dispatch?: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
): Promise<MLAnnotationModel[]> => {
  setIsLoading && setIsLoading(true);
  const response = await APIFetchAxios("/mlAnnotationModels").catch((error) => {
    const errorDetail = error?.response?.data?.detail;
    dispatch &&
      snackbarHelper(
        errorDetail
          ? `AI models fetching failed: ${errorDetail}`
          : "AI models fetching failed!",
        "error",
      );
    setIsLoading && setIsLoading(false);

    return Promise.reject(error);
  });
  setIsLoading && setIsLoading(false);
  return response?.data;
};

/**
 * An endpoint to fetch a ML annotation model
 * @param params.mlAnnotationModelID The ML annotation model ID
 * @returns The ML annotation model
 */
export const fetchMLAnnotationModel = async (params: {
  mlAnnotationModelID: string;
}): Promise<MLAnnotationModel> => {
  const response = await APIFetchAxios(
    `/mlAnnotationModels/${params.mlAnnotationModelID}`,
  ).catch((error) => {
    snackbarHelper("AI model fetching failed!", "error");
    return Promise.reject(error);
  });
  return response?.data;
};

/**
 * An endpoint to fetch a ML annotation models
 * @param params
 * @param params.ann_run_id The annotation run ID
 * @param dispatch
 * @returns The ML annotation model
 */
export const fetchMLAnnotationModelsOfAnAnnotationRun = async (
  params: { ann_run_id: string },
  dispatch?: Dispatch,
): Promise<MLAnnotationModel[]> => {
  const response = await APIFetchAxios(
    `/annotationRun/${params?.ann_run_id}/mlAnnotationModels`,
  ).catch((error) => {
    dispatch && snackbarHelper("AI model fetching failed!", "error");
    return Promise.reject(error);
  });
  return response?.data;
};

/**
 * An endpoint to create an ML annotation model
 * @param {object} body - The body of the request
 * @param {string} body.annotation_run_id - The annotationRun ID
 * @param {string} body.ml_annotation_model - The mlAnnotationModel to be created
 * @param {Dispatch} dispatch - The dispatch function
 * @param {Function} setIsLoading - A function to set the loading state
 * @returns {Promise<object>} - Returns the response data
 */
export const postCreateMLAnnotationModel = async (
  body: MLAnnotationModelCreate,
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  setIsLoading && setIsLoading(true);

  const response = await APIPostWithBodyAxios(
    "/mlAnnotationModels",
    body,
  ).catch((error: AxiosError<any>) => {
    const errorDetail = error?.response?.data?.detail;
    snackbarHelper(
      errorDetail
        ? `ML annotation model creation: ${errorDetail}`
        : "ML annotation model creation failed!",
      "error",
    );
    setIsLoading && setIsLoading(false);
    return Promise.reject(error);
  });
  snackbarHelper("AI model created successfully!");
  setIsLoading && setIsLoading(false);
  return response?.data;
};

/**
 * A function to update an ML annotation model by ID (name, user_group)
 * @param mlAnnotationModelID The ML annotation model ID
 * @param body.name The name of the ML annotation model
 * @param body.user_group The user group of the ML annotation model
 * @param dispatch The dispatch function
 * @param setIsLoading The function to set the loading state
 * @returns The updated ML annotation model
 */
export const patchMLAnnotationModel = async (
  mlAnnotationModelID: string,
  body: {
    name?: string;
    user_group?: string;
  },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
): Promise<MLAnnotationModel> => {
  setIsLoading && setIsLoading(true);
  const response = await APIPatchWithBodyAxios(
    `/mlAnnotationModels/${mlAnnotationModelID}`,
    body,
  ).catch((error: AxiosError<any>) => {
    const errorDetail = error?.response?.data?.detail;
    snackbarHelper(
      `AI Model updated failed: ${errorDetail}` || "AI Model updated failed!",
      "error",
    );
    setIsLoading && setIsLoading(false);
    return Promise.reject();
  });
  snackbarHelper("AI model updated successfully!");
  setIsLoading && setIsLoading(false);
  return response?.data;
};

/**
 * A function to delete an ML annotation model by ID
 * @param params.mlAnnotationModelID The ML annotation model ID
 * @param dispatch The dispatch function
 * @param setIsLoading The function to set the loading state
 */
export const deleteMLAnnotationModel = async (
  params: { mlAnnotationModelID: string },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  setIsLoading && setIsLoading(true);
  await APIDeleteAxios(`/mlAnnotationModels/${params.mlAnnotationModelID}`)
    .then(() => {
      snackbarHelper("AI model archived successfully!");
      setIsLoading && setIsLoading(false);
      return Promise.resolve();
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        `AI model archive: ${errorDetail}` || "AI model archive failed!",
        "error",
      );
      setIsLoading && setIsLoading(false);
      return Promise.reject();
    });
};
