import { useState } from "react";

import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Close } from "@mui/icons-material";

import { useHotkeys } from "react-hotkeys-hook";
import { map } from "lodash";
import SidebarIcon from "sections/SidebarMenu/SidebarIcon";

const classes = {
  kbd: {
    display: "inline-block",
    color: " #363636",
    textDecoration: "none",
    alignText: "center",
    backgroundColor: "#efefef",

    padding: "3px 7px",
    margin: "5px 3px",
    width: "fit-content",

    borderRadius: "4px",
    boxShadow:
      "inset 0 0 25px #e8e8e8,0 1px 0 #c3c3c3,0 2px 0 #c9c9c9,0 2px 3px #333",
    textShadow: "0 1px 0 #f6f6f6",
  },
  backdrop: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 100,
    backdropFilter: "blur(5px)",
  },
  dialogTitle: {
    fontFamily: "UntitledSans",
    fontSize: "18px",
    lineHeight: "25.2px",
    fontWeight: 700,
    marginLeft: "auto",
  },
};

const HelpDialog = () => {
  const [openHelpDialog, setOpenHelpDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenHelpDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenHelpDialog(false);
  };

  const explorationHotKeys: any = {
    "F 1": "Help dialog",
    d: "Download dataset",
    f: "Open filter panel",
    h: "Go to dashboard",
    shift: "Select multiple items",
  };

  const comparisonHotKeys: any = {
    "F 1": "Help dialog",
    "←,→": "Navigate to previous / next item",
  };

  useHotkeys(
    "f1",
    () => {
      setOpenHelpDialog(!openHelpDialog);
    },
    [openHelpDialog],
  );

  return (
    <div>
      <SidebarIcon icon="hotkeys" onClick={handleOpenDialog} small={true} />

      <Backdrop open={openHelpDialog} sx={classes.backdrop}>
        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={openHelpDialog}
          onClose={handleCloseDialog}
        >
          <DialogContent>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "2em",
              }}
            >
              <Typography sx={classes.dialogTitle}>Hotkeys</Typography>
              <Close
                onClick={handleCloseDialog}
                style={{ marginLeft: "auto" }}
              />
            </div>
            <div className="grid grid-cols-2">
              <div className="flex flex-col items-center font-bold mb-4 w-full">
                Exploration view
              </div>
              <div className="flex flex-col items-center font-bold mb-4">
                Detail view
              </div>
              <div className="flex justify-center">
                <div className="w-5/6">
                  {map(
                    Object?.keys(explorationHotKeys),
                    (hotkeys: any, key) => (
                      <div
                        key={key}
                        className="grid grid-cols-3 my-2 justify-around"
                      >
                        <div>
                          {map(hotkeys?.split(","), (hotkey: any, key2) => (
                            <div key={key2} style={classes.kbd}>
                              {hotkey}
                            </div>
                          ))}
                        </div>
                        <div className="col-span-2">
                          {explorationHotKeys[hotkeys]}
                        </div>
                      </div>
                    ),
                  )}
                </div>
              </div>
              <div className="flex justify-center">
                <div className="w-5/6">
                  {map(Object?.keys(comparisonHotKeys), (hotkeys: any, key) => (
                    <div key={key} className="grid grid-cols-3 my-2 w-full">
                      <div>
                        {map(hotkeys?.split(","), (hotkey: any, key2) => (
                          <div key={key2} style={classes.kbd}>
                            {hotkey}
                          </div>
                        ))}
                      </div>
                      <div className="col-span-2">
                        {comparisonHotKeys[hotkeys]}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </Backdrop>
    </div>
  );
};

export default HelpDialog;
