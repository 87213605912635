import _ from "lodash";
import {
  AttributeGroupEnum,
  AttributeMetadataModel,
} from "models/attributes.model";
import {
  TrainingSetAttribute,
  TrainingSetModel,
  TrainingSetStatus,
} from "models/trainingSet.model";

import { ReactComponent as AIIcon } from "assets/ai.svg";
import { ReactComponent as EditIcon } from "assets/edit.svg";

import { DatasetModel } from "models/dataset.model";

import NewTrainingSetAttributeSubsetTable from "Pages/AIManager/TrainingSetManager/CreateNewTrainingSetDialog/NewTrainingSetAttributeList/NewTrainingSetAttributeSubsetTable";
import { getTrainingSetSubsetQuery } from "helpers/apis/trainingSet";
import Loading from "components/UtilComponents/Loading";
import TooltipTruncateEllipsis from "components/Tooltips/TooltipTruncateEllipsis";
import StatusLabelFilled from "components/Labels/StatusLabelFilled";
import { snakeCaseToText } from "components/utilFunctions";
import { getTrainingSetStatusColor } from "Pages/AIManager/TrainingSetManager/trainingSetStatusColor";

type Props = {
  trainingSet: TrainingSetModel;
  attributesMetaData: AttributeMetadataModel[] | null;
  dataset: DatasetModel | null;
  subsets: DatasetModel[] | null;
  handleChangeTrainingSet?: () => void;
  isLoadingAttributeMetaData?: boolean;
};

// This function is used to render the status label of the model
const renderStatusLabel = (trainingSet: TrainingSetModel) => {
  let label = "Not started";
  if (trainingSet !== null) {
    label = trainingSet?.status;
  }

  const circleColor = getTrainingSetStatusColor(trainingSet?.status);

  return (
    <StatusLabelFilled
      label={snakeCaseToText(label)}
      circleColor={circleColor}
    />
  );
};

const TrainingSetOverview = ({
  trainingSet,
  dataset,
  attributesMetaData,
  subsets,
  handleChangeTrainingSet,
  isLoadingAttributeMetaData,
}: Props) => {
  const renderName = () => {
    return (
      <div className="label-layer text-base gap-x-2 w-fit">
        {trainingSet?.name}
        {dataset?.name && (
          <div className="text-sm text-paletteGray-9">{dataset?.name}</div>
        )}
        {renderChangeButton()}
      </div>
    );
  };

  const renderPossibleAnswers = () => {
    if (
      !trainingSet.possible_answers ||
      trainingSet.possible_answers?.length === 0
    )
      return null;

    return (
      <div className="flex flex-col gap-y-2">
        <div className="font-medium">Possible answers</div>
        <div className="flex flex-wrap gap-2">
          {_.map(trainingSet.possible_answers, (answer, index) => {
            return (
              <div key={index} className="label-layer max-w-xs">
                <TooltipTruncateEllipsis>{answer}</TooltipTruncateEllipsis>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderChangeButton = () => {
    if (!handleChangeTrainingSet) return null;

    return (
      <div
        className="p-1 cursor-pointer"
        onClick={() => handleChangeTrainingSet && handleChangeTrainingSet()}
      >
        <EditIcon className="w-4 h-4 text-paletteGray-7 hover:text-paletteGray-9" />
      </div>
    );
  };

  const renderAttributeList = () => {
    if (isLoadingAttributeMetaData)
      return (
        <div>
          <Loading />
        </div>
      );

    return (
      <div className="w-full rounded-md flex flex-col gap-2">
        <div className="font-medium">Attribute list</div>
        {_.map(trainingSet.training_attributes, (attribute, index) => {
          const subsetQuery = getTrainingSetSubsetQuery(attribute);
          return (
            <div
              key={attribute.attribute_id}
              className="label-layer p-2 py-2 flex flex-col items-start justify-center gap-y-2 transition-all duration-300"
            >
              {renderAttributeMetaData(attribute, index)}
              {subsets && subsetQuery && subsetQuery.value && (
                <NewTrainingSetAttributeSubsetTable
                  trainingSetAttribute={attribute}
                  allSubsets={subsets}
                  onlyOverview={true}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderAttributeMetaData = (
    attribute: TrainingSetAttribute,
    index: number,
  ) => {
    const attributeMetadata = _.find(attributesMetaData, {
      id: attribute.attribute_id,
    });

    if (!attributeMetadata) return null;

    return (
      <div className="w-full">
        <div className="flex gap-x-1">
          <div className="text-paletteGray-9">{index + 1}.</div>
          <TooltipTruncateEllipsis>
            {attributeMetadata?.question}
          </TooltipTruncateEllipsis>
          {attributeMetadata?.attribute_group ===
            AttributeGroupEnum.ML_ANNOTATION_ATTRIBUTE && (
            <AIIcon className="w-[18px] h-[18px] text-palettePurple " />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex justify-between">
        {renderName()}
        {renderStatusLabel(trainingSet)}
      </div>

      {renderPossibleAnswers()}
      {renderAttributeList()}
    </div>
  );
};

export default TrainingSetOverview;
