import _ from "lodash";
import { AIAnnotationRunModel } from "models/aiAnnotationRun.model";
import { AnnotationRunModel } from "models/annotationRun.model";
import { DatasetModel } from "models/dataset.model";
import { ManagerFiltersModel } from "models/global.model";
import { MLAnnotationModel } from "models/mlAnnotationModel.model";
import { PipelineModel } from "models/pipelines.model";
import { TrainingSetModel } from "models/trainingSet.model";

export const filterByAllAppliedFilters = (
  items: (
    | PipelineModel
    | AnnotationRunModel
    | TrainingSetModel
    | MLAnnotationModel
    | AIAnnotationRunModel
    | DatasetModel
  )[],
  appliedFilters: ManagerFiltersModel,
  keys: {
    created_at: string;
    user_group: string;
  } = {
    created_at: "created_at",
    user_group: "user_group",
  },
) => {
  // Skip filtering if no filters are applied
  if (Object.values(appliedFilters).every((value) => value === null)) {
    return items;
  }

  let filteredItems = {};

  _.map(items, (item) => {
    let withinDateRange = true;
    let haveUserGroup = true;

    if (appliedFilters.created_at !== null) {
      for (const dateRange of Object.values(appliedFilters.created_at)) {
        const { from, to } = dateRange;
        // const createdAt = item?.created_at
        const createdAt = _.get(item, keys.created_at);

        const itemCreatedAt = new Date(createdAt).setHours(0, 0, 0, 0);
        const fromDate = new Date(from).setHours(0, 0, 0, 0);
        const toDate = new Date(to).setHours(23, 59, 59, 999);

        if (itemCreatedAt >= fromDate && itemCreatedAt <= toDate) {
          withinDateRange = true;
          // Break the loop if the item is within the date range of at least one filter
          break;
        } else {
          withinDateRange = false;
        }
      }
    }

    if (appliedFilters.user_group !== null) {
      const userGroup = _.get(item, keys.user_group);
      haveUserGroup = appliedFilters.user_group?.has(userGroup) ?? false;
    }

    if (withinDateRange && haveUserGroup) {
      filteredItems = {
        ...filteredItems,
        [item.id]: item,
      };
    }
  });

  return filteredItems;
};
