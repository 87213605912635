import React from "react";
import { ReactComponent as OpenIcon } from "assets/open.svg";

type Props = {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const ExploreResultsButton = ({ onClick }: Props) => {
  return (
    <button onClick={(e) => onClick(e)} className="button-layer w-max mr-2">
      <OpenIcon className="w-[18px] h-[18px]" />
      Explore results
    </button>
  );
};

export default ExploreResultsButton;
