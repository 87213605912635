import { APIFetchAxios } from "routes/Auth";

interface CloudFrontCookies {
  "CloudFront-Policy": string;
  "CloudFront-Signature": string;
  "CloudFront-Key-Pair-Id": string;
}

/** Check if CloudFront cookies exist and have more than specified minutes of lifetime for a specific dataset
 *
 * @param {string} datasetId - The dataset ID to check cookies for
 * @param {number} minMinutesRemaining - Minimum minutes of lifetime required
 * @returns {boolean} - Whether cookies exist and are valid
 */
const hasValidCDNCookies = (
  datasetId: string,
  minMinutesRemaining: number = 10,
): boolean => {
  const cookies = document.cookie.split(";").reduce(
    (acc, cookie) => {
      const [name, value] = cookie.trim().split("=");
      acc[name] = value;
      return acc;
    },
    {} as Record<string, string>,
  );

  // Check if CloudFront-Policy cookie exists and contains the dataset ID
  const policyCookie = "CloudFront-Policy";
  if (!(policyCookie in cookies)) {
    return false;
  }

  // Decode the base64 policy and check if it contains the dataset ID
  try {
    const decodedPolicy = atob(cookies[policyCookie].split("/")[0]); // Split to remove domain part
    if (!decodedPolicy.includes(datasetId)) {
      return false;
    }
  } catch (error) {
    console.warn("Failed to decode CloudFront policy:", error);
    return false;
  }

  // Check if CloudFront-Expires cookie exists
  const expiresCookie = "CloudFront-Expires";
  if (!(expiresCookie in cookies)) {
    return false;
  }

  // Extract expiry timestamp from the beginning of the cookie value
  const expiryMatch = cookies[expiresCookie].match(/^(\d+)/);
  if (!expiryMatch) {
    return false;
  }

  const expiryTimestamp = parseInt(expiryMatch[1], 10);
  const currentTime = Math.floor(Date.now() / 1000); // Convert to seconds to match CloudFront timestamp
  const minutesRemaining = (expiryTimestamp - currentTime) / 60;

  // Also verify other required cookies exist
  const requiredCookies = ["CloudFront-Key-Pair-Id", "CloudFront-Signature"];

  const allCookiesPresent = requiredCookies.every(
    (cookieName) => cookieName in cookies,
  );

  return minutesRemaining > minMinutesRemaining && allCookiesPresent;
};

/** Fetch cookie for access to a dataset on the CDN
 *
 * @param {string} datasetId - The id of the dataset
 **/
export const fetchCDNCookie = async (datasetId: string) => {
  if (hasValidCDNCookies(datasetId)) {
    return;
  }

  try {
    const response = await APIFetchAxios(`/cookies/CDN/${datasetId}`);
    const cookies = response.data as CloudFrontCookies;

    // Set each CloudFront cookie with more permissive settings
    const cookieOptions = "; path=/; secure; SameSite=None";

    Object.entries(cookies).forEach(([name, value]) => {
      document.cookie = `${name}=${value}${cookieOptions}`;
    });

    const currentCookies = document.cookie.split(";").reduce(
      (acc, cookie) => {
        const [name, value] = cookie.trim().split("=");
        acc[name] = value;
        return acc;
      },
      {} as Record<string, string>,
    );

    const missingCookies = Object.keys(cookies).filter(
      (name) => !(name in currentCookies),
    );

    if (missingCookies.length > 0) {
      console.warn("Failed to set some cookies:", missingCookies);
    }

    return;
  } catch (error) {
    console.error("Error setting CDN cookies:", error);
  }
};
