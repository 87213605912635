import { useKeycloak } from "@react-keycloak/web";
import { useMemo } from "react";
import { FeatureFlagEnum } from "models/featureFlags.model";
import { UserRoleModel } from "models/users.model";

/**
 * Hook that returns a function for conditionally adding items based on feature flag access.
 * Access is granted if either:
 * - User has the specific resource role matching the feature flag
 * - User has the internal realm role
 *
 * The returned function takes a feature flag and an element, and returns an array
 * containing the element if the user has access, or an empty array if not.
 *
 * @returns Function that accepts a feature flag and element, returning array containing
 * the element if user has access
 *
 * @example
 * ```tsx
 * const addFeatureItem = useItemFeatureGate();
 *
 * const items = [
 *   ...addFeatureItem(FeatureFlagEnum.REPORTS, {
 *     label: "Create Report",
 *     onClick: () => {}
 *   }),
 *   // other items...
 * ];
 * ```
 */
export function useItemFeatureGate() {
  const { keycloak } = useKeycloak();

  return useMemo(() => {
    return function addFeatureItem<Type>(
      flag: FeatureFlagEnum,
      element: Type,
    ): Type[] {
      if (
        keycloak.hasResourceRole(flag.toString()) ||
        keycloak.hasRealmRole(UserRoleModel.Internal)
      )
        return [element];
      else return [];
    };
  }, [keycloak]);
}
