import { fetchAIAnnotationRun } from "helpers/apis/aiAnnotationRun";
import {
  AIManagerScreenRouteModel,
  AIMangerViewRouteEnum,
} from "models/routes.model";
import AIAnnotationRunManagerTable from "Pages/AIManager/AIAnnotationRunManager/AIAnnotationRunManagerTable";
import AIAnnotationRunOverview, {
  AIAnnotationRunManagerOverviewDialogState,
} from "Pages/AIManager/AIAnnotationRunManager/AIAnnotationRunOverview";
import CreateNewAIAnnotationRunDialog from "Pages/AIManager/AIAnnotationRunManager/CreateNewAIAnnotationRunDialog";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const AIAnnotationRunManager = () => {
  const params: AIManagerScreenRouteModel = useParams();

  const [overviewDialogState, setOverviewDialogState] =
    useState<AIAnnotationRunManagerOverviewDialogState>({
      show: false,
      aiAnnotationRun: null,
    });

  useEffect(() => {
    if (params.view !== AIMangerViewRouteEnum.AIAnnotationRuns) return;
    if (!params.id || overviewDialogState.aiAnnotationRun) return;

    fetchAIAnnotationRun({ aiAnnotationRunID: params.id }).then(
      (fetchedTrainingSet) => {
        setOverviewDialogState({
          show: true,
          aiAnnotationRun: fetchedTrainingSet,
        });
      },
    );
  }, [params.id]);

  return (
    <div className="h-full">
      <AIAnnotationRunManagerTable
        setOverviewAIAnnotationRun={setOverviewDialogState}
      />
      <CreateNewAIAnnotationRunDialog />

      {overviewDialogState.show && (
        <AIAnnotationRunOverview
          overviewDialog={overviewDialogState}
          setOverviewDialog={setOverviewDialogState}
        />
      )}
    </div>
  );
};

export default AIAnnotationRunManager;
