import ExploreResultsButton from "components/Buttons/ExploreResultsButton";
import { AIAnnotationRunModel } from "models/aiAnnotationRun.model";
import { getUrlPrefix } from "Pages/AnnotationRunManager/AnnotationRunManagerTableAction";
import React from "react";

type Props = { aiAnnotationRun: AIAnnotationRunModel };

const ExploreAIAnnotationRunResultsButton = ({ aiAnnotationRun }: Props) => {
  const handleExploreButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    getUrlPrefix(aiAnnotationRun?.subset_id).then((url_prefix) => {
      // AI attributes with no goliat project have the ann run node ID as attr ID
      const attributeID = aiAnnotationRun?.attribute_metadata_id;
      const datasetID = aiAnnotationRun?.dataset_id;
      const exploreUrl = `${url_prefix}/${datasetID}/main_dataset?sel_attr__attributes.${attributeID}.dataset_id="${datasetID}"`;
      const newWnd = window.open(exploreUrl, "_blank", "noopener,noreferrer");
      if (newWnd) {
        newWnd.opener = null;
      }
    });
  };

  return <ExploreResultsButton onClick={handleExploreButtonClick} />;
};

export default ExploreAIAnnotationRunResultsButton;
