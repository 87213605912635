import { FeatureFlagEnum } from "models/featureFlags.model";
import { useKeycloak } from "@react-keycloak/web";
import { useMemo } from "react";
import { UserRoleModel } from "models/users.model";

/**
 * Hook to check if a feature flag is enabled for the current user.
 * Access is granted if either:
 * - No feature flag is required
 * - User has the specific resource role matching the feature flag
 * - User has the internal realm role
 *
 * @param flag - The feature flag to check for access
 * @returns boolean indicating if the feature is enabled
 *
 * @example
 * ```tsx
 * const isFeatureEnabled = useFeature(FeatureFlagEnum.NewUI);
 * ```
 */
export const useFeature = (flag: FeatureFlagEnum) => {
  const { keycloak } = useKeycloak();

  return useMemo(() => {
    if (!flag) return false;

    return (
      keycloak.hasResourceRole(flag.toString()) ||
      keycloak.hasRealmRole(UserRoleModel.Internal)
    );
  }, [flag, keycloak]);
};
