import { AnnotatableEnum, SelectedViewModel } from "models/global.model";
import {
  AIManagerScreenRouteModel,
  AIMangerViewRouteEnum,
  AnnotationRunManagerScreenRouteModel,
  ExplorationScreenRouteModel,
  PipelineDesignerScreenRouteModel,
} from "models/routes.model";
import { matchPath } from "react-router-dom";
import { allUsersRoutes, customContractUsersRoutes } from "routes";
import { AIMangerViewEnum } from "store/aiManagerSlice";

export const getExplorationRouteFromSelectedView = (
  selectedView: SelectedViewModel,
  routeParams: ExplorationScreenRouteModel,
) => {
  switch (selectedView) {
    case AnnotatableEnum.MediaObject:
      return getExplorationObjectPageRoute(routeParams);
    case AnnotatableEnum.Instance:
      return getExplorationInstancePageRoute(routeParams);
    case AnnotatableEnum.Media:
    default:
      return getExplorationMediaPageRoute(routeParams);
  }
};

export const getExplorationSelectedViewFromRoute = (
  route: string,
): SelectedViewModel => {
  if (matchPath(route, allUsersRoutes.explorationMediaPage.path)) {
    return AnnotatableEnum.Media;
  } else if (matchPath(route, allUsersRoutes.explorationObjectPage.path)) {
    return AnnotatableEnum.MediaObject;
  } else if (matchPath(route, allUsersRoutes.explorationInstancePage.path)) {
    return AnnotatableEnum.Instance;
  }
  return AnnotatableEnum.Media;
};

export const getExplorationMediaPageRoute = (
  routeModel: ExplorationScreenRouteModel,
) => {
  const baseRoute = allUsersRoutes.explorationMediaPage.path;
  return baseRoute
    .replace(":id", routeModel.id)
    .replace(":subset_id", routeModel.subset_id);
};

export const getExplorationObjectPageRoute = (
  routeModel: ExplorationScreenRouteModel,
) => {
  const baseRoute = allUsersRoutes.explorationObjectPage.path;
  return baseRoute
    .replace(":id", routeModel.id)
    .replace(":subset_id", routeModel.subset_id);
};

export const getExplorationInstancePageRoute = (
  routeModel: ExplorationScreenRouteModel,
) => {
  const baseRoute = allUsersRoutes.explorationInstancePage.path;
  return baseRoute
    .replace(":id", routeModel.id)
    .replace(":subset_id", routeModel.subset_id);
};

export const getPipelineDesignerPageRoute = (
  routeModel: PipelineDesignerScreenRouteModel,
) => {
  const baseRoute = customContractUsersRoutes.pipelineDesignerPage.path;
  return baseRoute.replace(":pipeline_id", routeModel.pipeline_id);
};

export const getAnnotationRunManagerPageRoute = (
  routeModel: AnnotationRunManagerScreenRouteModel,
) => {
  const baseRoute = customContractUsersRoutes.annotationRunManagerPage.path;
  return baseRoute.replace(":annotation_run_id", routeModel.annotation_run_id);
};

export const getAIManagerPageRoute = (
  routeModel: AIManagerScreenRouteModel,
) => {
  const baseRoute = customContractUsersRoutes.aiManagerPage.path;
  const id = routeModel.id ? routeModel.id : "";

  return baseRoute.replace(":view", routeModel.view).replace(":id", id);
};

export const getAIManagerSelectedViewFromRoute = (
  route: string,
): AIMangerViewEnum => {
  if (matchPath(route, customContractUsersRoutes.aiManagerPage.path)) {
    const params = matchPath(
      route,
      customContractUsersRoutes.aiManagerPage.path,
    )?.params as AIManagerScreenRouteModel;
    return getAIManagerEnumFromViewParam(params.view as AIMangerViewRouteEnum);
  }
  return AIMangerViewEnum.AIModels;
};

export const getAIManagerViewParamFromEnum = (
  view: AIMangerViewEnum,
): AIMangerViewRouteEnum => {
  switch (view) {
    case AIMangerViewEnum.AIModels:
      return AIMangerViewRouteEnum.AIModels;
    case AIMangerViewEnum.TrainingSets:
      return AIMangerViewRouteEnum.TrainingSets;
    case AIMangerViewEnum.AIAnnotationRuns:
      return AIMangerViewRouteEnum.AIAnnotationRuns;
    default:
      return AIMangerViewRouteEnum.AIModels;
  }
};

export const getAIManagerEnumFromViewParam = (
  viewParam: AIMangerViewRouteEnum,
): AIMangerViewEnum => {
  switch (viewParam) {
    case AIMangerViewRouteEnum.AIModels:
      return AIMangerViewEnum.AIModels;
    case AIMangerViewRouteEnum.TrainingSets:
      return AIMangerViewEnum.TrainingSets;
    case AIMangerViewRouteEnum.AIAnnotationRuns:
      return AIMangerViewEnum.AIAnnotationRuns;
    default:
      return AIMangerViewEnum.AIModels;
  }
};
