import { FeatureFlagEnum } from "models/featureFlags.model";
import { ReactNode } from "react";
import { useFeature } from "helpers/hooks/useFeature";

interface Props {
  flag: FeatureFlagEnum;
  invert?: boolean;
  children: ReactNode | ((isEnabled: boolean) => ReactNode);
}

/**
 * Component for conditional rendering based on feature flags.
 * Supports both direct children and render prop patterns.
 *
 * @example
 * ```tsx
 * // Simple usage
 * <Feature flag={FeatureFlagEnum.NewUI}>
 *   <NewComponent />
 * </Feature>
 *
 * // With render prop
 * <Feature flag={FeatureFlagEnum.NewUI}>
 *   {(isEnabled) => isEnabled ? <NewUI /> : <OldUI />}
 * </Feature>
 *
 * // Inverted (show when feature is disabled)
 * <Feature flag={FeatureFlagEnum.NewUI} invert>
 *   <LegacyComponent />
 * </Feature>
 * ```
 */
export const Feature = ({ flag, invert = false, children }: Props) => {
  let isEnabled = useFeature(flag);
  if (invert) {
    isEnabled = !isEnabled;
  }

  if (typeof children === "function") {
    return <>{children(isEnabled)}</>;
  }

  return isEnabled ? <>{children}</> : null;
};
