import { useKeycloak } from "@react-keycloak/web";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import EditName from "components/Inputs/EditName";
import SharedByTeamLabel from "components/Labels/SharedByTeamLabel";
import StatusLabel from "components/Labels/StatusLabel";
import DataTable, { DataTableColumn } from "components/Table/DataTable";
import TooltipTruncateEllipsis from "components/Tooltips/TooltipTruncateEllipsis";
import UserGroupSelection from "components/UtilComponents/UserGroupSelection";
import { snakeCaseToText } from "components/utilFunctions";
import {
  deleteAIAnnotationRun,
  patchAIAnnotationRun,
} from "helpers/apis/aiAnnotationRun";
import { useWebsocket } from "helpers/contexts/WebsocketContext";
import { handleDefaultOnMessageWebsocket } from "helpers/websockets";
import {
  dataUpdateMessageFunctionRouter,
  updateAIAnnotationRunsWithNewAIAnnotationRun,
} from "helpers/websockets/dataUpdateHelper";
import _ from "lodash";
import {
  AIAnnotationRunModel,
  AIAnnotationRunStatusEnum,
} from "models/aiAnnotationRun.model";
import { AIMangerViewRouteEnum } from "models/routes.model";
import {
  WebsocketEventGroupEnum,
  WebSocketMessageModel,
} from "models/websockets.model";

import AIAnnotationRunManagerTableActions from "Pages/AIManager/AIAnnotationRunManager/AIAnnotationRunManagerTableActions";
import { AIAnnotationRunManagerOverviewDialogState } from "Pages/AIManager/AIAnnotationRunManager/AIAnnotationRunOverview";
import AIAnnotationRunStatusChip from "Pages/AIManager/AIAnnotationRunManager/AIAnnotationRunStatusChip";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { getAIManagerPageRoute } from "routes/routesHelper";
import { filterByAllAppliedFilters } from "sections/ManagerFilters/managerFiltersHelpers";
import {
  fetchAIAnnotationRunsStore,
  setAIAnnotationRuns,
} from "store/aiManagerSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

export interface AIAnnotationRunManagerPopoverConfirmDialogState {
  show: boolean;
  action: "archive";
  aiAnnotationRunID: string | null;
}

interface Props {
  setOverviewAIAnnotationRun: (
    aiAnnotationRun: AIAnnotationRunManagerOverviewDialogState,
  ) => void;
}

const AIAnnotationRunManagerTable = ({ setOverviewAIAnnotationRun }: Props) => {
  const keycloak = useKeycloak();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const aiManagerSlice = useAppSelector((state) => state.aiManagerSlice);

  const { ws } = useWebsocket();

  const [confirmDialogState, setConfirmDialogState] =
    useState<AIAnnotationRunManagerPopoverConfirmDialogState>({
      show: false,
      action: "archive",
      aiAnnotationRunID: null,
    });

  const inputRefArray = useRef<{ [key: string]: HTMLInputElement }>({});
  const [renameInputID, setRenameInputID] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  // Websocket update listener
  useEffect(() => {
    if (ws !== null) {
      ws.onmessage = (event) => {
        handleDefaultOnMessageWebsocket(event, dispatch, history);

        const webSocketMessage = JSON.parse(
          event.data,
        ) as WebSocketMessageModel;

        if (webSocketMessage.type === "data_update_message") {
          const eventGroup = dataUpdateMessageFunctionRouter(webSocketMessage);

          if (eventGroup === WebsocketEventGroupEnum.AI_ANNOTATION_RUN_UPDATE) {
            if (aiManagerSlice.aiAnnotationRuns === null) return;

            const updatedAnnotationRuns =
              updateAIAnnotationRunsWithNewAIAnnotationRun(
                webSocketMessage,
                aiManagerSlice.aiAnnotationRuns,
              );
            dispatch(setAIAnnotationRuns(updatedAnnotationRuns));
          }
        }
      };
    }
  }, [ws, aiManagerSlice.aiAnnotationRuns]);

  const updateAIAnnotationRuns = () => {
    dispatch(fetchAIAnnotationRunsStore());
  };

  const tableColumns: DataTableColumn[] = [
    {
      field: "name",
      headerName: "Name",
      span: 30,
      cell: (row) => {
        const aiAnnotationRun = row as AIAnnotationRunModel;
        const isAIAnnotationRunSharedByMyTeam =
          aiAnnotationRun?.owner !== keycloak?.keycloak?.idTokenParsed?.sub;
        return (
          <div
            className="flex overflow-hidden pr-3"
            onClick={(e) => e.stopPropagation()}
          >
            <EditName
              item={row as Record<string, any>}
              inputRefArray={inputRefArray}
              editInputID={renameInputID}
              setEditInputID={setRenameInputID}
              handleRename={(newName: string) =>
                handleRenameAIAnnotationRun(aiAnnotationRun?.id, newName)
              }
              withToolTip={true}
              fontSize="text-normal"
            />
            {isAIAnnotationRunSharedByMyTeam && <SharedByTeamLabel />}
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created",
      span: 15,
      cell: (row) => {
        const aiAnnotationRun = row as AIAnnotationRunModel;
        const date = new Date(aiAnnotationRun?.created_at);
        return (
          <TooltipTruncateEllipsis className="pr-3" data-test="Created_value">
            {date.toLocaleString()}
          </TooltipTruncateEllipsis>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      span: 15,
      cell: (row) => {
        const aiAnnotationRun = row as AIAnnotationRunModel;
        return <AIAnnotationRunStatusChip status={aiAnnotationRun?.status} />;
      },
    },
    {
      field: "user_group",
      headerName: "Team",
      span: 15,
      cell: (row) => {
        const aiAnnotationRun = row as AIAnnotationRunModel;
        return (
          <UserGroupSelection
            selectedUserGroup={aiAnnotationRun?.user_group}
            onChanges={(newUserGroup: string) =>
              patchAIAnnotationRun(
                aiAnnotationRun?.id,
                { user_group: newUserGroup },
                dispatch,
                setIsLoading,
              ).then(() => updateAIAnnotationRuns())
            }
          />
        );
      },
    },

    {
      field: "",
      headerName: "Actions",
      headerClassName: "justify-end",
      sortable: false,
      span: 25,
      cell: (row) => {
        const aiAnnotationRun = row as AIAnnotationRunModel;
        return (
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-full flex flex-row-reverse justify-start"
          >
            <AIAnnotationRunManagerTableActions
              aiAnnotationRun={aiAnnotationRun}
              setRenameInputID={setRenameInputID}
              setConfirmDialogState={setConfirmDialogState}
            />
          </div>
        );
      },
    },
  ];

  const handleRenameAIAnnotationRun = (
    aiAnnotationRunID: string,
    newName: string,
  ) => {
    patchAIAnnotationRun(
      aiAnnotationRunID,
      { name: newName },
      dispatch,
      setIsLoading,
    ).then(() => updateAIAnnotationRuns());
  };

  const handleDeleteAIAnnotationRun = () => {
    if (!confirmDialogState.aiAnnotationRunID) return;

    deleteAIAnnotationRun(
      {
        aiAnnotationRunID: confirmDialogState.aiAnnotationRunID,
      },
      dispatch,
      setIsLoading,
    ).then(() => {
      updateAIAnnotationRuns();
      setConfirmDialogState({ ...confirmDialogState, show: false });
    });
  };

  const renderDeleteConfirmationDialog = () => {
    if (!confirmDialogState.aiAnnotationRunID) return null;

    const aiAnnotationRunName =
      _.find(aiManagerSlice.aiAnnotationRuns, [
        "id",
        confirmDialogState.aiAnnotationRunID,
      ])?.name || "";
    return (
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogState.show}
        setIsConfirmDialogOpen={(newState) =>
          setConfirmDialogState({ ...confirmDialogState, show: newState })
        }
        text={`Are you sure you want to delete the AI annotation run
                  "${aiAnnotationRunName}"?`}
        confirmButtonText="Confirm"
        handleOnSuccess={() => handleDeleteAIAnnotationRun()}
      />
    );
  };

  const handleRowClick = (row: AIAnnotationRunModel) => {
    setOverviewAIAnnotationRun({
      show: true,
      aiAnnotationRun: row,
    });
    const url = getAIManagerPageRoute({
      view: AIMangerViewRouteEnum.AIAnnotationRuns,
      id: row.id,
    });
    history.push(url);
  };

  const filterAiAnnotationRuns = () => {
    if (!aiManagerSlice.aiAnnotationRuns) return [];

    return filterByAllAppliedFilters(
      aiManagerSlice.aiAnnotationRuns,
      aiManagerSlice.appliedFilters,
    ) as AIAnnotationRunModel[];
  };

  return (
    <>
      <DataTable
        rows={filterAiAnnotationRuns() as []}
        columns={tableColumns}
        defaultSort={{ name: "created_at", direction: "desc" }}
        onRowClick={(row) => handleRowClick(row as AIAnnotationRunModel)}
        isLoading={aiManagerSlice.loadingAIAnnotationRuns}
        isDisabled={isLoading}
        searchValue={aiManagerSlice.searchValue}
      />
      {renderDeleteConfirmationDialog()}
    </>
  );
};

export default AIAnnotationRunManagerTable;
