import { useEffect, useState } from "react";
import {
  AnnotationRunModel,
  AnnotationRunNodeModel,
} from "models/annotationRun.model";
import {
  fetchAnnotationRunNodes,
  fetchAnnotationRuns,
} from "helpers/apis/annotationRun";
import { useAppDispatch } from "store/hooks";
import AnnotationRunManagerTable from "Pages/AnnotationRunManager/AnnotationRunManagerTable";
import { ManagerFiltersModel } from "models/global.model";
import ManagerFilters from "sections/ManagerFilters";
import { filterByAllAppliedFilters } from "sections/ManagerFilters/managerFiltersHelpers";
import RefreshButton from "components/Buttons/refresh";
import PipelineAndAnnotationRunTabs, {
  ManagerTabsOptions,
} from "sections/PipelineAndAnnotationRunTabs";
import SearchBar from "components/Inputs/SearchBar";
import _ from "lodash";
import { useKeycloak } from "@react-keycloak/web";
import Header from "sections/Headers/GeneralHeader";
import { ReactComponent as AnnotationRunManagerIcon } from "assets/annotation_runs.svg";
import { useWebsocket } from "helpers/contexts/WebsocketContext";
import {
  WebsocketEventGroupEnum,
  WebSocketMessageModel,
} from "models/websockets.model";
import {
  dataUpdateMessageFunctionRouter,
  updateAnnotationRunsWithNewAnnotationRun,
} from "helpers/websockets/dataUpdateHelper";
import { handleDefaultOnMessageWebsocket } from "helpers/websockets";
import { useHistory, useParams } from "react-router-dom";
import AnnotationRunOverview from "sections/AnnotationRunOverview";
import { AnnotationRunManagerScreenRouteModel } from "models/routes.model";

const AnnotationRunManager = () => {
  const params: AnnotationRunManagerScreenRouteModel = useParams();

  const dispatch = useAppDispatch();
  const keycloak = useKeycloak();

  const history = useHistory();

  const { ws } = useWebsocket();

  const [selectedTabValue, setSelectedTabValue] = useState<ManagerTabsOptions>(
    ManagerTabsOptions.all,
  );
  const [annotationRuns, setAnnotationRuns] = useState<AnnotationRunModel[]>(
    [],
  );

  const [annotationRunNodes, setAnnotationRunNodes] = useState<{
    [key: string]: AnnotationRunNodeModel[];
  }>({});

  const [appliedFilters, setAppliedFilters] = useState<ManagerFiltersModel>({
    created_at: null,
    user_group: null,
  });
  const [searchValue, setSearchValue] = useState<string>("");

  const [overviewDialog, setOverviewDialog] = useState<{
    isOpen: boolean;
    annotationRunID: string | null;
  }>({
    isOpen: false,
    annotationRunID: null,
  });

  // Websocket update listener
  useEffect(() => {
    if (ws !== null) {
      ws.onmessage = (event) => {
        handleDefaultOnMessageWebsocket(event, dispatch, history);

        const webSocketMessage = JSON.parse(
          event.data,
        ) as WebSocketMessageModel;

        if (webSocketMessage.type === "data_update_message") {
          const eventGroup = dataUpdateMessageFunctionRouter(webSocketMessage);

          if (eventGroup === WebsocketEventGroupEnum.ANNOTATION_RUN_UPDATE) {
            const updatedAnnotationRuns =
              updateAnnotationRunsWithNewAnnotationRun(
                webSocketMessage,
                annotationRuns,
              );
            setAnnotationRuns(updatedAnnotationRuns);
          }
        }
      };
    }
  }, [ws, annotationRuns]);

  // Fetch annotationRuns
  useEffect(() => {
    fetchAnnotationRuns(dispatch).then((data) => {
      setAnnotationRuns(data);
    });
  }, []);

  useEffect(() => {
    if (params.annotation_run_id) {
      setOverviewDialog({
        isOpen: true,
        annotationRunID: params.annotation_run_id,
      });
    }
  }, [params.annotation_run_id]);

  const filterAnnotationRuns = () => {
    let filteredAnnotationRuns = filterByAllAppliedFilters(
      annotationRuns,
      appliedFilters,
    ) as AnnotationRunModel[];

    // Filter by tab
    if (selectedTabValue === ManagerTabsOptions.me) {
      filteredAnnotationRuns = _.filter(
        filteredAnnotationRuns,
        (annotationRun) =>
          annotationRun?.owner === keycloak?.keycloak?.idTokenParsed?.sub,
      );
    } else if (selectedTabValue === ManagerTabsOptions.team) {
      filteredAnnotationRuns = _.filter(
        filteredAnnotationRuns,
        (annotationRun) =>
          annotationRun?.owner !== keycloak?.keycloak?.idTokenParsed?.sub,
      );
    }
    return filteredAnnotationRuns;
  };

  return (
    <div className="mx-auto full-height-with-margin bg-white flex flex-col self-center border-[1px] border-paletteGray-5 rounded-xl m-1 mr-1">
      <Header
        title="Annotation runs"
        icon={
          <AnnotationRunManagerIcon className="w-5 h-5 text-paletteBlack-1" />
        }
        iconBgcolor="bg-gradient-to-b from-[#1DD3B3] to-[#0CB89A]"
      />

      <div className="px-[24px] min-h-0 py-4 flex-1 flex flex-col">
        <div className="w-full flex justify-between">
          <div className="w-fit">
            <PipelineAndAnnotationRunTabs
              items={annotationRuns}
              selectedTabValue={selectedTabValue}
              setSelectedTabValue={setSelectedTabValue}
              labelSuffix="annotation runs"
            />
          </div>

          <div className="min-w-0 flex-1 flex flex-row-reverse items-center gap-x-2">
            <div className="w-[500px]">
              <SearchBar
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                placeholder="Search for name, project ID..."
              />
            </div>
            <ManagerFilters
              appliedFilters={appliedFilters}
              setAppliedFilters={setAppliedFilters}
            />

            <RefreshButton
              callBackFunction={() => {
                fetchAnnotationRuns(dispatch).then((data) => {
                  setAnnotationRuns(data);
                });

                if (!_.isEmpty(annotationRunNodes)) {
                  let annotationRunNodeIDs: string[] = [];
                  _.map(
                    annotationRunNodes,
                    (annotationRunNodeList) =>
                      (annotationRunNodeIDs = [
                        ...annotationRunNodeIDs,
                        ...annotationRunNodeList.map(
                          (annotationRunNode) => annotationRunNode.id,
                        ),
                      ]),
                  );
                  fetchAnnotationRunNodes(annotationRunNodeIDs).then((data) => {
                    setAnnotationRunNodes(_.groupBy(data, "annotation_run_id"));
                  });
                }
              }}
            />
          </div>
        </div>

        <div className="w-full flex-1 min-h-0 pt-4" data-test="table_grid_area">
          <AnnotationRunManagerTable
            annotationRuns={filterAnnotationRuns()}
            setAnnotationRuns={setAnnotationRuns}
            searchValue={searchValue}
            annotationRunNodes={annotationRunNodes}
            setAnnotationRunNodes={setAnnotationRunNodes}
            setOverviewDialog={setOverviewDialog}
          />
        </div>
      </div>

      {overviewDialog.isOpen && (
        <AnnotationRunOverview
          overviewDialog={overviewDialog}
          setOverviewDialog={setOverviewDialog}
        />
      )}
    </div>
  );
};

export default AnnotationRunManager;
