import _ from "lodash";
import { useState } from "react";
import StandardPopover from "components/Popovers/StandardPopover";
import CustomCheckbox from "components/Inputs/Checkbox";
import { ManagerFiltersModel } from "models/global.model";
import { useAppSelector } from "store/hooks";

interface Props {
  selectedUserGroups: ManagerFiltersModel["user_group"];
  setSelectedUserGroups: (newValue: ManagerFiltersModel["user_group"]) => void;
  setMainAnchorEl: (element: HTMLElement | null) => void;
}

const UserGroupPopover = ({
  selectedUserGroups,
  setSelectedUserGroups,
  setMainAnchorEl,
}: Props) => {
  const userGroups = useAppSelector(
    (state) => state.userManagementSlice.availableUserGroups,
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const renderTitleButton = () => {
    return (
      <button
        className={`button-select-layer ${
          !_.isNull(anchorEl) ? "button-select-layer-active" : ""
        }`}
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
      >
        User groups
      </button>
    );
  };

  const renderListOfOptions = () => {
    const options = [
      // {
      //   value: null,
      //   label: "--No Group--",
      // },
      ..._.map(userGroups, (group) => {
        return {
          value: group,
          label: group,
        };
      }),
    ];

    return options?.map((option) => {
      return renderOption(option.label, option.value);
    });
  };

  const renderOption = (label: string, value: string) => {
    const isChecked =
      selectedUserGroups === null ? false : selectedUserGroups.has(value);
    return (
      <div
        key={label}
        className="button-select-layer justify-between gap-x-4"
        onClick={() => handleOptionClick(value, isChecked)}
      >
        <div>{label}</div>
        <CustomCheckbox
          checked={isChecked}
          onChange={() => {
            //
          }}
        />
      </div>
    );
  };

  const handleOptionClick = (value: string, isChecked: boolean) => {
    // Remove user group
    if (isChecked) {
      if (selectedUserGroups !== null && selectedUserGroups.size === 1) {
        setSelectedUserGroups(null);
        return;
      }

      const newSelectedUserGroups = new Set(selectedUserGroups);
      newSelectedUserGroups.delete(value);
      setSelectedUserGroups(newSelectedUserGroups);
    }
    // Add user group
    else {
      const newSelectedUserGroups = new Set(selectedUserGroups);
      newSelectedUserGroups.add(value);
      setSelectedUserGroups(newSelectedUserGroups);
    }
  };

  return (
    <StandardPopover
      id="user_group_filter_popover"
      anchorEl={anchorEl}
      handlePopoverOpen={(event) => setAnchorEl(event.currentTarget)}
      handlePopoverClose={() => {
        setAnchorEl(null);
        setMainAnchorEl(null);
      }}
      buttonElement={renderTitleButton()}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      offSetPopover={{
        left: -8,
        top: -4,
      }}
    >
      <div className="w-auto">{renderListOfOptions()}</div>
    </StandardPopover>
  );
};

export default UserGroupPopover;
