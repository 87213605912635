import { TrainingSetStatus } from "models/trainingSet.model";

export const getTrainingSetStatusColor = (
  status: TrainingSetStatus,
): string => {
  switch (status) {
    case TrainingSetStatus.SUBMITTED:
    case TrainingSetStatus.VALIDATING:
      return "bg-paletteYellow";
    case TrainingSetStatus.VALIDATION_FAILED:
      return "bg-paletteRed";
    case TrainingSetStatus.CREATED:
      return "bg-paletteGreen";
    default:
      return "bg-paletteGray6";
  }
};
