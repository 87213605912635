export interface ExplorationScreenRouteModel {
  id: string;
  subset_id: string;
}

export interface MediaDetailsScreenRouteModel {
  id: string;
  subset_id: string;
  media_id: string;
}

export interface InstanceDetailsScreenRouteModel {
  id: string;
  subset_id: string;
  instance_id: string;
}

export interface MediaObjectDetailsScreenRouteModel {
  id: string;
  subset_id: string;
  mediaObjectID: string;
}

export interface AttributeManagementScreenRouteModel {
  dataset_id: string;
  subset_id: string;
}

export interface SubsetsManagerScreenRouteModel {
  dataset_id: string;
  subset_id: string;
}

export interface TagsManagerScreenRouteModel {
  dataset_id: string;
  subset_id: string;
}

export interface InternalScreenRouteModel {
  dataset_id: string;
  subset_id: string;
}

export interface PipelineDesignerScreenRouteModel {
  pipeline_id: string;
}

export interface AnnotationRunManagerScreenRouteModel {
  annotation_run_id: string;
}

export interface AIManagerScreenRouteModel {
  view: AIMangerViewRouteEnum;
  id?: string;
}

export enum AIMangerViewRouteEnum {
  AIModels = "ai-models",
  TrainingSets = "training-sets",
  AIAnnotationRuns = "ai-annotation-runs",
}
