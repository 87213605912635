import React, { useRef, useState } from "react";
import _ from "lodash";
import { DataTableColumn } from "components/Table/DataTable";
import { DatasetModel } from "models/dataset.model";
import EditName from "components/Inputs/EditName";
import {
  getDownloadFile,
  postCreateReport,
  postCreateSubsetExportFile,
} from "helpers/apis/download";
import generateDateString from "helpers/functions/generateDateString";
import StandardPopoverWrapper from "components/Popovers/StandardPopoverWrapper";
import MoreButton from "components/Buttons/MoreButton";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useHistory } from "react-router-dom";
import {
  fetchDashboardInformation,
  fetchSubsetInformationForDataset,
} from "store/dashboardSlice";
import { deleteSubset, postRenameSubset } from "helpers/apis/subsets";
import { resetStoreOnSubsetChange } from "store/util/resetStore";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import { getExplorationRouteFromSelectedView } from "routes/routesHelper";
import { getSelectedViewFromSubsetType } from "helpers/functions/selectedViewHelpers";
import { setSelectedView } from "store/appSlice";
import snackbarHelper from "helpers/snackbarHelperFn";
import { FeatureFlagEnum } from "models/featureFlags.model";

import { useItemFeatureGate } from "helpers/hooks/useItemFeatureGate";

interface props {
  subsets: DatasetModel[];
}

const SubsetSubTable = ({ subsets }: props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const addFeatureItem = useItemFeatureGate();

  const selectedView = useAppSelector((state) => state.appSlice.selectedView);

  const inputRefArray = useRef<{ [key: string]: HTMLInputElement }>({});
  const [editInputID, setEditInputID] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [deleteItemID, setDeleteItemID] = useState<string | null>(null);
  const [isEditNameActive, setIsEditNameActive] = useState(false);

  const handleRenameSubset = (
    newName: string,
    subsetID: string,
    datasetID: string,
  ) => {
    if (newName.length < 3) {
      snackbarHelper(
        `Subset name must be at least 3 characters long, but '${newName}' has ${newName.length} character(s).`,
        "error",
      );
      return;
    }

    postRenameSubset({ subsetID: subsetID, newName: newName }, dispatch).then(
      () =>
        dispatch(fetchSubsetInformationForDataset({ datasetID: datasetID })),
    );
  };

  const handleSubsetClick = (subset: DatasetModel): void => {
    if (_.isNull(editInputID) && subset.parent_dataset) {
      const subsetType = getSelectedViewFromSubsetType(subset.subset_type);
      subsetType && dispatch(setSelectedView(subsetType));
      const explorationRoute = getExplorationRouteFromSelectedView(
        selectedView,
        {
          id: subset?.parent_dataset,
          subset_id: subset?.id,
        },
      );
      if (history.location.pathname !== explorationRoute) {
        resetStoreOnSubsetChange(dispatch);
        history.push(explorationRoute);
      }
    }
  };

  const handleDelete = () => {
    if (!deleteItemID) return;
    deleteSubset({ subsetID: deleteItemID }, dispatch, setIsLoading)
      .then(() => {
        setIsConfirmDialogOpen(false);
        // Todo: Replace with fetchSubsetInformationForDataset
        dispatch(fetchDashboardInformation());
      })
      .catch(() => {
        setIsConfirmDialogOpen(false);
      });
  };

  const columns: DataTableColumn[] = [
    {
      field: "name",
      headerName: "Name",
      span: 40,
      className: "font-bold",
      cell: (row) => {
        const subset = row as DatasetModel;
        return (
          <div className="flex items-center">
            <div
              className={`flex gap-x-1 text-sm w-fit px-2 py-[3.5px] rounded-[4px] overflow-hidden pr-3 ${
                !isEditNameActive ? "hover:bg-paletteGray-4" : ""
              }`}
            >
              <EditName
                onFocus={() => setIsEditNameActive(true)}
                onMouseOver={() => setIsEditNameActive(true)}
                item={row as Record<string, any>}
                inputRefArray={inputRefArray}
                editInputID={editInputID}
                setEditInputID={setEditInputID}
                handleRename={(newName: string) => {
                  handleRenameSubset(
                    newName,
                    subset?.id,
                    subset?.parent_dataset || "",
                  );
                }}
                fontSize="text-base"
              />
            </div>
            {subset?.export_id && (
              <div
                className="border-paletteOrange h-fit px-[6px] py-[3px] border-[1px] bg-white
            rounded-[10px] items-center flex
             gap-x-2 text-paletteOrange w-fit text-xs"
                onClick={(e) => {
                  e.stopPropagation();
                  getDownloadFile(
                    {
                      datasetID: subset?.parent_dataset || "",
                      fileID: subset?.export_id,
                    },
                    dispatch,
                    setIsLoading,
                  );
                }}
              >
                READY TO DOWNLOAD
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "num_medias",
      headerName: "Medias",
      span: 20,
      cell: (row) => {
        const dataset = row as DatasetModel;
        return <div>{dataset?.num_medias?.toLocaleString("fi-FI")}</div>;
      },
    },
    {
      field: "num_media_objects",
      headerName: "Objects",
      span: 20,
      cell: (row) => {
        const dataset = row as DatasetModel;
        return <div>{dataset?.num_media_objects?.toLocaleString("fi-FI")}</div>;
      },
    },
    {
      field: "creation_timestamp",
      headerName: "Created",
      span: 15,
      cell: (row) => {
        const dataset = row as DatasetModel;
        return (
          <div className="flex gap-x-1">
            {!_?.isNull(dataset?.creation_timestamp)
              ? generateDateString(new Date(dataset?.creation_timestamp), false)
              : "-"}
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "",
      sortable: false,
      span: 5,
      cell: (row) => {
        const subset = row as DatasetModel;
        const itemsList = [
          ...addFeatureItem(FeatureFlagEnum.REPORTS, {
            label: "Create insights report",
            onItemClick: () => {
              subset?.parent_dataset &&
                postCreateReport(subset?.parent_dataset, subset?.id);
            },
          }),
          {
            label: "Create JSON export",
            onItemClick: () => {
              postCreateSubsetExportFile(
                {
                  datasetID: subset?.parent_dataset || "",
                  subsetID: subset?.id,
                },
                dispatch,
              );
            },
          },
          {
            label: "Rename",
            onItemClick: () => {
              setEditInputID(subset?.id);
            },
          },
          {
            label: "Delete",
            onItemClick: () => {
              setIsConfirmDialogOpen(true);
              setDeleteItemID(subset?.id);
            },
            className: "text-paletteRed",
            disabled: subset?.object_category,
          },
        ];
        return (
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-full flex flex-row-reverse"
          >
            <StandardPopoverWrapper
              id={subset?.id}
              buttonElement={<MoreButton />}
              itemsList={itemsList}
            />
          </div>
        );
      },
    },
  ];

  // Function to render the cells of the table (the data) for each row
  const renderRowCells = (row: DatasetModel) => {
    return _.map(columns, (col, key) => {
      const columnSpan = col?.span || 100 / columns?.length;
      const cellValue = col?.cell ? col?.cell(row) : _.get(row, col?.field);

      return (
        <div
          key={key}
          className={`flex flex-col gap-x-2 span justify-center text-md ${col?.headerClassName}`}
          style={{ width: `${columnSpan}%` }}
        >
          {cellValue}
        </div>
      );
    });
  };

  return (
    <div
      className="max-h-[298px] overflow-y-auto pr-2"
      data-test="dashboard_subset_list"
      style={{ scrollbarGutter: "stable" }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {_.map(subsets, (subset) => {
        return (
          <div
            key={subset.id}
            className="flex"
            onClick={(e) => {
              e.stopPropagation();
              handleSubsetClick(subset);
            }}
          >
            {renderRowCells(subset)}
          </div>
        );
      })}
      <ConfirmDialog
        isConfirmDialogOpen={isConfirmDialogOpen}
        setIsConfirmDialogOpen={setIsConfirmDialogOpen}
        text={`Are you sure you want to delete the subset "${_.find(subsets, [
          "id",
          deleteItemID,
        ])?.name}"?`}
        handleOnSuccess={handleDelete}
        isLoading={isLoading}
      />
    </div>
  );
};

export default SubsetSubTable;
