import StatusLabel from "components/Labels/StatusLabel";
import { snakeCaseToText } from "components/utilFunctions";
import { AIAnnotationRunStatusEnum } from "models/aiAnnotationRun.model";

type Props = { status: AIAnnotationRunStatusEnum };

const AIAnnotationRunStatusChip = ({ status }: Props) => {
  const renderStatusChip = () => {
    let color;
    switch (status) {
      case AIAnnotationRunStatusEnum.STARTED:
      case AIAnnotationRunStatusEnum.CREATED:
      case AIAnnotationRunStatusEnum.ANNOTATING:
        color = "bg-paletteYellow";
        break;
      case AIAnnotationRunStatusEnum.CREATION_FAILED:
      case AIAnnotationRunStatusEnum.ANNOTATION_FAILED:
      case AIAnnotationRunStatusEnum.AI_ANNOTATION_FAILED:
        color = "bg-paletteRed";
        break;
      case AIAnnotationRunStatusEnum.DONE:
        color = "bg-paletteGreen";
        break;
      default:
        color = "bg-paletteGray6";
    }

    return <StatusLabel label={snakeCaseToText(status)} colorClass={color} />;
  };

  return renderStatusChip();
};

export default AIAnnotationRunStatusChip;
